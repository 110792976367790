.about_page {


  background-image: url('../images/about2.svg'), url(''), url('../images/about4.svg'), url('../images/about5.svg');
  background-position: 10% 5%, right top, 87% 7%, right 12%;
  background-repeat: no-repeat;
  background-size: 300px, 330px, 150px, 150px;

}


.contact_page {

  background-image: url('../images/con1.svg'), url('../images/con2.svg');
  background-position: left 6.5%, right top;
  background-repeat: no-repeat;
  background-size: 300px, 200px;
  /* background-attachment: fixed; */

}


.text_to_image_page {

  background-image: url('../images/TTI_astro.svg'), url('../images/TTI_planet.svg');
  background-position: 20% 80%, right top;
  background-repeat: no-repeat;
  background-size: 220px, 270px;
  background-attachment: fixed, scroll;


}

#colorful-abstract-nebula-space-background {
  width: 100%;
}

#Background {
  max-width: 100%;
  height: auto;

}


.Ai_chat_page {

  background-image: url('../images/AIChat_planet1.svg'), url('../images/aichat_planet2.svg');
  background-position: 20% 30%, right 2.5%;
  background-repeat: no-repeat;
  background-size: 170px, 170px;
  backdrop-filter: opacity(0.2);
  /* background-attachment: fixed, scroll; */

}




@keyframes Ai_chat_page {

  0% {
    background-position: left 30%, right 30%, center 5%, center;


  }

  25% {
    background-position: 50% 60%, 50% 80%, center 5%, center;
  }

  50% {
    background-position: right 30%, left 30%, center 5%, center;
  }

  75% {
    background-position: 50% 40%, 50% 20%, center 5%, center;
  }

  100% {
    background-position: left 30%, right 30%, center 5%, center;

  }

}


.Portfolio_page {
  background-image: url('../../../public/Backgrounds/Astro_Gradient_1.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  animation: moving 100s infinite linear;


}

.Cosultation_page {
  background-image: url('../images/cons_1.svg'), url('../images/cons_2.svg');
  background-position: left top, right 25%;
  background-repeat: no-repeat;
  background-size: 500px, 200px;
  background-attachment: scroll, scroll;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.55;
}

.Careers_page {
  background-image: url('../images/Careers_Planet.svg'), url('../images/Careers_Space_Man.svg');
  background-position: -6% 5%, 90% center;
  background-repeat: no-repeat;
  background-size: 800px, 170px;
  background-attachment: fixed;

}


.Team_page {

  background-image: url('../images/Team_Planet_X.svg'), url('../images/Team_Jupiter.svg');
  background-position: -15% 15%, 105% top;
  background-repeat: no-repeat;
  background-size: 400px, 250px;
  background-attachment: scroll, fixed;
}

.Services_page {

  background-image: url('../images/services_planet.svg'), url('../images/services_astro.svg');
  background-position: left 3%, right 12%;
  background-repeat: no-repeat;
  background-size: 420px, 350px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  width: 100%;
  height: 200vh;
  z-index: -1;
}


.Blog_page {

  background-image: url('../images/blog_astro.svg'), url('../images/blog_planet.svg');
  background-position: 50px 25vmin, right 25vmin;
  background-repeat: no-repeat;
  background-size: 200px, 400px;
}

.Home_Page_1 {

  background-image: url('../../../public/Backgrounds/About_Background_2.svg'), url('../../../public/Backgrounds/Home_Background_1.jpg');
  background-position: 120% center, center;
  background-repeat: no-repeat;
  background-size: 50%, cover;
  background-attachment: local;
  filter: contrast(120%);

}




.cosmic_1 {

  background-color: #000000;
  background-size: cover;
  background-repeat: no-repeat;
  /* animation: moving 50s infinite linear; */
  background-attachment: fixed;

}

@keyframes moving {

  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }

}

.rf {

  width: 100%;
  height: 100vh;
}


#myVideo {
  width: 100%;
  position: fixed;
  z-index: -1;

}

.cosmic_2 {

  background: linear-gradient(315deg, #0b1f3a, #761040, #000000);

}

































@media screen and (min-width: 768px) and (max-width: 1024px) {


  .about_page {
    background-size: 270px, 300px, 120px, 120px;
    background-position: 8% 3%, right top, 85% 5%, right 10%;

  }

  .contact_page {

    background-size: 270px, 170px;


  }

  .Careers_page {
    background-size: 600px, 150px;
    background-position: -6% 4%, 90% center;

  }

  .text_to_image_page {

    background-position: 15% 70%, right top;
    background-size: 190px, 230px;

  }

  .Ai_chat_page {


    background-size: 150px, 150px;

  }

  .Services_page {


    background-position: left 3%, 105% 12%;

  }

  .Cosultation_page {

    background-size: 400px, 180px, cover;

  }

}

@media screen and (min-width: 480px) and (max-width: 768px) {

  .about_page {
    background-size: 240px, 270px, 90px, 90px;
    background-position: 7% 2%, right top, 84% 4%, right 9%;

  }

  .contact_page {

    background-size: 240px, 140px;

  }

  .Careers_page {
    background-size: 400px, 130px;
    background-position: -6% 3%, 92% center;

  }

  .text_to_image_page {

    background-position: 10% 60%, right top;
    background-size: 180px, 190px;


  }

  .Ai_chat_page {


    background-size: 140px, 140px;

  }

  .Services_page {

    background-image: url('../images/services_planet.svg'), url('../images/services_astro.svg');
    background-size: 300px, 230px;
    background-position: left 3%, 110% 12%;


  }

  .Cosultation_page {

    background-size: 300px, 170px, cover;

  }
}





@media screen and (max-width: 480px) {

  .about_page {
    background-size: 180px, 210px, 50px, 50px;
    background-position: 10% 1%, right top, 83% 3%, right 8%;

  }

  .contact_page {

    background-size: 160px, 90px;


  }

  .Careers_page {
    background-size: 200px, 110px;
    background-position: -6% 2%, 93% center;

  }

  .text_to_image_page {

    background-position: 5% 80%, right top;
    background-size: 130px, 120px;



  }

  .Ai_chat_page {


    background-size: 90px, 90px;
    background-position: 20% 40%, right 2.5%;


  }

  .Services_page {

    background-image: url('../images/services_planet.svg'), url('../images/services_astro.svg');
    background-size: 240px, 170px;
    background-position: left 3%, 110% 12%;

  }

  .Cosultation_page {

    background-size: 300px, 160px, cover;
    background-position: -120px top, right 25%, center;

  }

}



.gradient_now {
  background-image: linear-gradient(to bottom, #2d0947, #220c3a, #1a0b2d, #13071f, #070210);
}



.shadow {
  /* background-color:rgba(21, 21, 22, 0.85); */
}


.black{
  background: linear-gradient(to bottom , #0000000a  , rgba(0, 0, 0, 0.749) 20%  );
}

.blackless{
  background-color: rgba(0, 0, 0, 0.249);
}

.blob{
  position: fixed;
  height: 100vmin;
  left: 0%;
  top: 7.5%;

  z-index: -3;
  filter: blur(20px);
  transform: translate3d(0, 0, 0);

}



.planet{



}