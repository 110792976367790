.Main_2 {

  display: flex;
  padding: 0 5vw 0 5vw;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  /* background-color: #111111; */

}

.scroll {

  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 0;
  font-size: medium;
  color: white;
  cursor: pointer;
  padding: 0;
}

.scroll-text {

  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: rotate(-90deg);
}


.vertical-line {
  position: relative;
  width: 1px;
  height: 70px;
  background-color: white;
  color: white;
  left: 45%;
  margin-bottom: 2vh;
  transition: height 0.3s ease;
}

.scroll:hover .vertical-line {
  height: 100px;
}

.arrow-down {
  position: absolute;
  bottom: 0;
  left: -4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #ffffff;
}

.contact-form-button {
  background: none;
  padding: 0;
  border: 0;
  color: rgb(82, 30, 179);
  cursor: pointer;
  position: relative;
  left: 90%;
  outline: none;
}

.contact-form-button-close {
  background: none;
  padding: 0;
  border: 0;
  color: rgb(82, 30, 179);
  cursor: pointer;
  position: fixed;
  left: 90%;
  bottom: 30px;
  outline: none;
  z-index: 1000;
}


.telegram-icon {
  background-color: white;
  border-radius: 100px;
  transition:200ms;
}

.telegram-icon:hover{
  transform: rotate(-40deg);
}

.cross-icon {
  border-radius: 100px;
  color: rgba(255, 255, 255, 0.89);
  background-color: rgb(82, 30, 179);
}

.contact-form {

  display: flex;
  flex-direction: column;
  background-color: rgb(252, 252, 252);
  align-items: center;
  color: black;
  width: 40ch;
  height: 70ch;
  position: fixed;
  z-index: 999;
  top: 2%;
  right: 5% ;
  font-family: Arial, Helvetica;
  border-radius: 12px;
  gap: 2%;
  font-family: 'Roboto';
}

.contact-form-title {
  margin-top: 7ch;
  font-weight: bolder !important;
  font-size: small;
}

.contact-form-content {
  font-size: 0.7rem;
  width: 80%;
  text-align: center;
  line-height: 20px;
  margin-top: -1vh;
}

.contact-form-inputs{

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.contact-form-name {
  
  width: 80%;
  padding: 0 5% 0 5%;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  outline: 0;
  border-color: rgba(0, 0, 0, 0.13);
  height: 7ch;
  font-size: 0.7rem;


}

.contact-form-email {
  
  width: 80%;
  padding: 0 5% 0 5%;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  outline: 0;
  border-color: rgba(0, 0, 0, 0.13);
  height: 7ch;
  font-size: 0.7rem;


}

.contact-form-message {
  
  width: 81%;
  padding: 5% 5% 0 5%;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  outline: 0;
  border-color: rgba(0, 0, 0, 0.13);
  height: 21ch;
  font-family: Arial, Helvetica;
  font-size: 0.7rem;

}

.contact-form-send-button{

    color: white;
    background-color: blueviolet;
    width: 50%;
    border-radius: 30px;
    border: 0;
    padding: 10px;
    font-size: 0.7rem;

}

@media screen and (min-width: 768px) and (max-width: 1024px) {


    
    
     /* .Main_2{
      margin-top: -2ch;
     } */
}


@media screen and (min-width: 480px) and (max-width: 768px) {


  
 
    .contact-form-button-close{
      top:20%
    }


   .contact-form-button {
   
    left: 85%;
  }

  .scroll-text{
    font-size: 0.8rem;
  }

  .telegram-icon {
    background-color: white;
    border-radius: 100px;
    transition:200ms;
    width: 40px;
    height: 40px;
  }
  .contact-form-button{
    height: fit-content;
  }

}


@media screen and (max-width: 480px) {

   .Main_2{
    margin-top: -5px;
   }

  

   .contact-form-button {
   
    left: 78%;
  }
  .contact-form-button-close{
    left: 78%;
  }

  .scroll-text{
    font-size: 0.8rem;
  }

  .telegram-icon {
    background-color: white;
    border-radius: 100px;
    transition:200ms;
    width: 40px;
    height: 40px;
  }
  .contact-form-button{
    height: fit-content;
  }

  .contact-form{
    height: 90vh;
    width: 90%;
    overflow: scroll;

  }

}


@media screen and (max-height:  560px) {
  

  .contact-form{
    width: 90%;
    height: 100vh;

  }

  .contact-form-title{
    margin-top: 5px;
  }

  .contact-form-inputs{
    display: grid;
    grid-template-columns: auto  auto;
    grid-template-rows: auto auto;
    justify-items: center;
    align-content: center;
    gap: 0px;
    row-gap: 10px;
    width: 100%;
  }
  .contact-form-message {
  
    width: 85%;
    height: 20vh;
    padding: 2.5% 5% 0 5%;
    grid-column: 1 / -1;
   
  
  }
  .contact-form-send-button{
    margin-top: 10px;
  }

}