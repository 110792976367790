.About_Us {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    position: relative;
    height: calc(fit-content + 10ch);
    padding-bottom: 200px;
    justify-content: center;
    align-items: center;
    text-align: center;
}


button{
    cursor: pointer;
}

.title {
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    gap: 2.5%;
    width: 100%;
    font-family: 'Orbitron';
    text-align: center;
    align-content: center;
}

.title-name{
    text-align: center;
    width: 100%;

   
}

.horizontal-line {
    opacity: 0.6;
    width: 7%;
    height: 2.5px;
    background-size: 1px 2px, 1px 2px, 10px 10px;
    background-position: 0px 1px, 0px 1px, 9px 5px;
    background-repeat: no-repeat;
    margin: 20px 0;
    background-color: white !important;
    text-align: center;
    display: none;
}

.about-slogan {

    width: 100% !important;
    font-family: 'Orbitron';
}

.about-content {

    font-size: 0.9rem !important;
    line-height: 27px !important;
    width: 65%;
    max-width: 50vw;
    font-family: 'Roboto';
}

.about-learn-more {
    color: white;
    background: radial-gradient(circle, rgba(61, 6, 97, 0.822), rgba(128, 0, 128, 0.801));
    background-size: 0% ;
    background-position: 1%;
    background-repeat: no-repeat;
    margin-top: 25px;
    width: 140px;
    border-radius: 30px;
    border: 1px solid white;
    padding: 1vw;
    transition: all 0.5s ease-out;
    font-family: 'Roboto';
    

}

.about-learn-more:hover{
    width: 150px;
    background-size: 100%;
    font-weight: bold;
    border: 0;


}





@media screen and (min-width: 768px) and (max-width: 1024px) {

    .About_Us {

        display: flex;
        flex-direction: column;
        padding: 5vw;
        height: auto;
        
    }
    
    .title {
        display: flex;
        font-size: 0.8rem;
        font-weight: 600;
        gap: 2.5%;
        width: 100%;
    }
    
    
    .horizontal-line {
        width: 7%;
        height: 2.5px;
        background-size: 1px 2px, 1px 2px, 10px 10px;
        background-position: 0px 1px, 0px 1px, 9px 5px;
        background-repeat: no-repeat;
        margin: 20px 0;
    }
    
    .about-slogan {
    
        width: 100%;
    }
    
    .about-content {
    
        line-height: 24px;
        width: 85%;
    }
    
    .about-learn-more {
        color: white;
        margin-top: 20px;
        width: 170px;
        padding-top: 13px;
        padding-bottom: 13px;
        border-radius: 30px;
        border: 0;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px) {


    .title {
        display: flex;
        font-size: 0.6rem;
        font-weight: 600;
        gap: 2.5%;
        width: 100%;
        align-items: center;
    }

    .title-name{

        font-size: 0.6rem;


    }
  
 

    .horizontal-line {
        width: 20px !important;
       

    }

    .about-slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .about-content {
        width: 100%;
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 17px;
        margin-top: 0px;

    }


    .about-learn-more {
        width: 130px;
        padding-top: 11px;
        padding-bottom: 11px;
        margin-top: 15px;
        font-size: 0.65rem;

    }
}





@media screen and (max-width: 480px) {


   

    .title {
        display: flex;
        font-size: 0.6rem;
        font-weight: 600;
        gap: 2.5%;
        width: 100%;
        align-items: center;
    }

    .title-name{

        font-size: 0.6rem;
        font-weight: 600;



    }
   
    
    .about-slogan {
        width: 100%;
        font-size: 1.8rem;
    }
    .about-content {
    
        font-size: 0.6rem;
        line-height: 15px;
        width: 100%;
        margin-top: 5px;
    }

    .horizontal-line {
        width: 20px !important;
       

    }

  



    .about-learn-more {
        width: 120px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 0.6rem;
        margin-top: 14px;
    }
   
}