.Teamates{
   
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.teamates-content{
    font-size: 0.7rem;
    line-height: 24px;
    width: 70%;
    margin-top: -90px !important;
}

.teamates-catgs-bar {

    margin-top: 40px !important;
    display: flex;
    font-size: smaller;
    gap: 0.5vw;
}

.teamates-catg{
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    font-weight: 700;
    color: white;
}


.teamates-catg:hover{
   
    color: rgb(119, 2, 2) !important;
}

.teamates-catg:focus{
   
    color: rgb(119, 2, 2) !important;
}

.teamates-catg:active{
   
    color: rgb(119, 2, 2) !important;
}


.teamates{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr);
    row-gap: 30px;
    column-gap: 30px;
    
}

.teamate{
     width: 100%;
     text-align: center;
}


.post p{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.teamate-img{

    width: 100%;
    border-radius: 25px;
    width: auto-fill;
    transition: 200ms;
}

.teamate-role{
    font-size: 0.75rem;
    opacity: 0.4;
    font-family: parag;

}

.teamate-name{
    font-size: 0.9rem;
    font-weight: 600;
    font-family: parag;
}

.post-descriptiom{
    font-size: 0.75rem;

    opacity: 0.8;
    line-height: 20px;
}

.post-author {

    display: flex;
    gap: 2%;

}

.post-author-pic {

    width: 10%;
    height: 10%;
    border-radius: 200px;
}

.post-author-info {

    margin-left: 1%;
    margin-top: -10px;


}

.post-author-name {

    font-size: 0.85rem;

}

.post-author-date {

    font-size: 0.7rem;
    color: rgb(209, 209, 209) !important;
    margin-top: -10px;

}



  @media screen and (min-width: 768px) and (max-width: 1024px) {

    .teamates{
        grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(3,1fr);

    }
 
    .teamate-role{
        font-size: 0.65rem !important;
    }
    .teamate-name{
        font-size: 0.8rem !important;
    }

    
}

@media screen and (min-width: 480px) and (max-width: 768px) {

    .Teamates{
        padding-bottom: 150px;

    }    

    .teamates-content{
        width: 80%;
        font-size: 0.65rem;
        margin-top: 0px;
        line-height: 17px;
    }

    .teamates-catgs-bar{

        margin-top: 20px;
    }
    .teamates-catg{

        font-size: 0.65rem;
    }

    .teamates{
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);

    }
 
    .teamate-role{
        font-size: 0.6rem !important;
    }
    .teamate-name{
        font-size: 0.75rem !important;
    }


}


@media screen and (max-width: 520px) {

    

    .teamates-catgs-bar{

        margin-top: 30px;
        gap: 30px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-items: left;
       margin-left: 4px;
 
    }
    .teamates-catg{

       font-size: 0.7rem;
      
    }
    
    .catgs-bar-dash{
        display: none;
    }
    
}


@media screen and (max-width: 480px) {

    .Teamates{
        padding-bottom: 150px;

    }

    .teamates-content{
        width: 100%;
        font-size: 0.6rem;
        line-height: 17px;
    }   




    .teamates{
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(1,1fr);
    }
    
    .teamate-role{
        font-size: 0.6rem !important;
    }
}