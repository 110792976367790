.Consultation_And_Form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;

    height: auto;
    padding-bottom: 200px;
    padding-top: 100px;
    color: white;
}

.Consultation_And_Form-info{
    display: flex;
    flex-direction: column;
}

.consultation_And_Form_slogan {

    width: 50%;
}

.consultation_And_Form_content {

    font-size: 0.7rem;
    line-height: 24px;
    width: 70%;
}




.consultation-form {

    display: flex;
    flex-direction: column;
    background-color: #ffffff27;
    align-items: center;
    justify-content: end;
    color: black;
    min-width: 320px !important;
    width: 70vmin !important;
    max-width: 650px !important;
    padding: 30px;
    border-radius: 12px;
    gap: 20px;
    height: 52.5ch;    
    margin-left: 0 !important;
    margin-top: 30px;

  }
  

  .consultation-form-inputs {

   width: 100%;
   display: grid;
   grid-template-columns: repeat(2,1fr);
   grid-template-rows: repeat(2,1fr);
   column-gap: 5px;
   row-gap: 20px;

   
   }
  
  .consultation-form-input {
    
    width: 80%;
    padding:  0 7.5% 0  7.5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.781);
    outline: 0;
    border-color: rgba(0, 0, 0, 0.13);
    height: 8ch;
    font-size: 0.7rem;
    font-family: 'Roboto';

    
  
  }
  
  
  .consultation-form-message {
    
    width: 83%;
    padding: 5% 7.5% 0 7.5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.733);
    outline: 0;
    border-color: rgba(0, 0, 0, 0.13);
    height: 25ch;
    font-family: 'Roboto';
    font-size: 0.7rem;
    text-align: left;
  
  }
  
  .consultation-form-send-button{
  
      color: white;
      background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
      width: 130px;
      border-radius: 30px;
      border: 0;
      padding: 15px;
      font-size: 0.7rem;
      margin-left: auto;
      font-family: 'Roboto';

  
  }






  @media screen and (min-width: 768px) and (max-width: 1024px) {

    
    .consultation_And_Form_slogan {
    
        width: 80%;
    }
    
    .consultation_And_Form_content {
    
        line-height: 24px;
        width: 80%;
    }
    .consultation-form{
        margin-top: 15px;
        width: 80%;
        height: 46ch;
    }
   
    
}

@media screen and (min-width: 480px) and (max-width: 768px) {

    .Consultation_And_Form{
        
        flex-direction: column;
    }

    .consultation_And_Form_slogan {
        width: 100%;
        font-size: 1.8rem;
    }

    .consultation_And_Form_content {
        width: 80%;
        font-size: 0.65rem;
        margin-top: 0px;
        line-height: 22px;

    }

    .consultation-form{
        height: 46ch;
        width: 60%;
        margin-top: 40px;
        margin-left: 15%;

    }
   
    

}





@media screen and (max-width: 480px) {


    .Consultation_And_Form{
        
        flex-direction: column;
    }
   
    
    .consultation_And_Form_slogan {
        width: 100%;
        font-size: 1.7rem;
    }
    .consultation_And_Form_content {
    
        width: 100%;
        font-size: 0.60rem;
        line-height: 21px;
    }

    .consultation-form{
        width: 50vw !important;
        min-width: none !important;
        max-width: none !important;
        padding-left: 2.5vw !important;
        padding-right: 1vw !important;
        /* margin-left: 2.5%; */
        margin-top: 40px;
        height: 60ch;
        align-items: center;
        justify-content: center;
    }

    .consultation-form-inputs{
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(1,1fr);

    }

    .consultation-form-message{
        width: 83%;
    }

  
 
}