.Testimonials {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    height: calc(fit-content + 10ch);
    /* background-color: #111111; */
    color: white;
    margin-top: -100px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.title {
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    gap: 2.5%;
}


.horizontal-line {
    width: 7%;
    height: 2.5px;
    background-size: 1px 2px, 1px 2px, 10px 10px;
    background-position: 0px 1px, 0px 1px, 9px 5px;
    background-repeat: no-repeat;
    margin: 20px 0;
}

.testimonials-slogan {

    width: 25%;
}

.testimonials-content {

    font-size: 0.7rem;
    color: white;
    line-height: 24px;
    width: 50%;
    margin-top: 20px;
}


.testimonials-learn-more {
    color: white;
    background: linear-gradient(to right, blueviolet, purple);
    margin-left: 87%;
    margin-top: -4vh;
    width: 13%;
    border-radius: 30px;
    border: 0;
    padding: 1vw;
}


.testimonials-reviews {

    display: flex;
    width: 100%;
    gap: 2%;
    margin-top: 70px;
}

.testimonials-reviews-item {

    display: flex;
    flex-direction: column;
    height: 300px;
    padding: 9%;
    /* background: linear-gradient(to right, blueviolet, purple); */
    background: transparent;
    border: 1px solid white;
    border-radius: 25px;
    margin-right: 5%;
    margin-left: 0.3%;

    gap: 10%;


}

.review-author {

    display: flex;
    gap: 2%;
    width: 100%;
}

.review-author-pic {

    width: 15%;
    border-radius: 100px;
}

.review-author-info {

    margin-top: 1%;
    margin-left: 1%;
    text-align: left !important;


}

.review-author-name {

    font-size: 0.85rem;



}

.review-author-role {

    font-size: 0.7rem;
    color: rgb(209, 209, 209) !important;
    margin-top: -1vh;


}

.review-content {

    font-size: 0.7rem;
    line-height: 15px !important;
    color: #e4e3e3;
    text-align: left;


}

.review-stars {
    display: flex;
    margin-left: -1%;
    font-size: 200% !important;
    size: 6 !important;
    width: 100%;
    margin-bottom: 5vw !important;

    
}


.active {

    color: #ffffff;

    margin: 0.5vw;
    font-size: "1.5rem";
    position: relative;
    left: 37vw;
    border-radius: 100%;
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;

}


.inactive {

    color: rgb(145, 145, 145);

    margin: 0.5vw;
    font-size: "1.5rem";
    position: relative;
    left: 37vw;
    cursor: pointer;
    


}

.dots {
    position: relative;
    margin-bottom: 1vw !important;
    font-size: 0.8rem;


}


@media screen and (min-width: 768px) and (max-width: 1024px) {


    .testimonials-slogan {
        width: 50%;
    }  

    .testimonials-content {
        width: 70%;
        margin-top: 8px;
        }    

    

    .testimonials-learn-more {
        padding-top: 13px;
        padding-bottom: 13px;
        margin-left: auto;
    }


    .testimonials-reviews-item{

        width: calc(36vw - 1vh);
       
        gap: 5%;
    }
    .testimonials-reviews{

       
        padding-right: 4vh;
    }
    .review-author{
        gap: 4%;
    }
    .review-author-pic{
        width: calc(7ch);
    }
    .review-stars{
        font-size: 150% !important;

    }

   .dots{
    font-size: smaller;
   
   }

}  

@media screen and (max-width:960px) {
    .testimonials-reviews-item{

        width: 85%;
        height: 25ch;
        gap: 5%;
        padding: 5%;
        
    }
    .testimonials-reviews{

       margin-top: 50px;
       align-items: center;
    }

    .review-author{
        gap: 4%;
    }
    .review-author-pic{
        width: 6ch;
    }

    .review-author-info{
        margin-left: -3px;
    }

    .review-author-name{
        font-size: 0.6rem;
    }
    .review-author-role{
        font-size: 0.5rem;;
    }
    .review-content{
        margin-top: 10px;
        font-size: 0.55rem;
        line-height: 14px;
    }
    .review-stars{
        font-size: 125% !important;

    }

    .active {

        color: #ffffff;
    
        margin: 0.5vw;
        font-size: "1.5rem";
        position: relative;
        left: 39vw;
        border-radius: 100%;
        border: 0;
        outline: none;
        background: none;
        cursor: pointer;
    
    }
    
    
    .inactive {
    
        color: rgb(145, 145, 145);
    
        margin: 0.5vw;
        font-size: "1.5rem";
        position: relative;
        left: 39vw;
        cursor: pointer;
        
    
    
    }

   .dots{
    font-size: 0.4rem;
    margin-right: 0px !important;
   
   }
    
}


@media screen and (min-width: 480px) and (max-width: 768px) {


    .Testimonials {
        /* align-items: center; */
        height: calc(fit-content + 10ch)
    }


    .testimonials-slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .testimonials-content {
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 17px;
        width: 65%;

        margin-top: 5px;

    }


    .testimonials-learn-more {
        width: 130px;
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 0.65rem;
        margin-top: -16px;
        margin-left: auto;
    }

   
    .active {

        color: #ffffff;
    
        margin: 0.5vw;
        font-size: "1.5rem";
        position: relative;
        left: 40%;
        border-radius: 100%;
        border: 0;
        outline: none;
        background: none;
        cursor: pointer;
    
    }
    
    
    .inactive {
    
        color: rgb(145, 145, 145);
    
        margin: 0.5vw;
        font-size: "1.5rem";
        position: relative;
        left: 40%;
        cursor: pointer;
        
    
    
    }
  
   
   
}

@media screen and (min-width: 480px) and (max-width: 759px) {

    .active{
        left: 35%;
    }

    .inactive{
        left: 35%;
    }
   

}



@media screen and (max-width: 480px) {
    .Testimonials {

        display: flex;
        flex-direction: column;
        padding: 5vw;
        height: calc(fit-content + 10ch);
        color: white;
    }
    
    .title {
        display: flex;
        font-size: 0.8rem;
        font-weight: 600;
        gap: 2.5%;
    }
    
   
    
    .horizontal-line {
        width: 7%;
        height: 2.5px;
        background-color: rgb(255, 255, 255);
        background-size: 1px 2px, 1px 2px, 10px 10px;
        background-position: 0px 1px, 0px 1px, 9px 5px;
        background-repeat: no-repeat;
        margin: 20px 0;
    }
    
    .testimonials-slogan {
    
        width: 100%;
        font-size: 1.8rem;
    }
    
    .testimonials-content {
    
        line-height: 15px;
        width: 100%;
        font-size: 0.6rem;
        margin-top: 0px;
    }
    
    .testimonials-reviews-item{

        width: 90%;
        height: 21ch;
        gap: 5%;
        padding: 4%;
        
    }
    .testimonials-reviews{

       margin-top: 25px;
       align-items: center;
    }

    .review-author{
        gap: 4%;
    }
    .review-author-pic{
        width: 5ch;
    }

    .review-author-info{
        margin-left: -3px;
    }

    .review-author-name{
        font-size: 0.5rem;
    }
    .review-author-role{
        font-size: 0.4rem;;
    }
    .review-content{
        margin-top: 10px;
        font-size: 0.45rem;
        line-height: 10px;
    }

    .review-stars{
        font-size: 100% !important;

    }

   .dots{
    font-size: 0.4rem;
    margin-right: 40px !important;
    margin-top: -10vh;
   
   }
  
   
   

}