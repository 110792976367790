.AI_Chat{

    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    left: 5%;
    margin-top: 20px;
     width: 90%;
    padding-bottom: 200px;
     
}



.ai_chat_title{
    color: rgb(255, 255, 255);
    font-size: 2.2rem;
    letter-spacing: 7px;
    margin-bottom: 40px;
    font-family: 'Orbitron';
    
    
  }

  @media only screen and (max-width: 768px ){
  
    .ai_chat_title{
        font-size: 1.85rem;
    }
}

@media only screen and (max-width: 480px){
  
    
    .ai_chat_title{
      font-size:1.7rem;
    }
}