
.How_we_work{
    padding: 5vw;
    padding-bottom: 200px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.How_we_work_title{
    opacity: 1;
    font-weight: 800;
    font-family: 'Orbitron';


}

.How_we_work_content{

    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr);
    row-gap: 25px;
    column-gap: 25px;
    margin-top: 50px;
        


}

.How_we_work_item{
  
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    line-height: 20px;
    background-color: #202020b4;
    padding: 40px 30px 40px 30px;
    border-radius: 20px;    
    font-family: 'Roboto';
    max-width: 400px;


}

.How_we_work_item p{
  
   text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {}

@media screen and (min-width: 480px) and (max-width: 768px) {
  
    .How_we_work_title{
       font-size: 1.4rem;
    }
    .How_we_work_item{
        font-size: 0.6rem;
    }
    .How_we_work_content{
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);

    }
   
 }

 @media screen and (max-width: 480px) {
  
    .How_we_work_title{
        font-size: 1.4rem;
     }
     .How_we_work_item{
         font-size: 0.6rem;
     }
     .How_we_work_content{
         grid-template-columns: repeat(1,1fr);
         grid-template-rows: repeat(1,1fr);
 
     }
      
  
 }