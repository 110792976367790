.Portfolio {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    position: relative;
    height: calc(fit-content + 10ch);
    padding-bottom: 200px;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.title {
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    gap: 2.5%;
}


.horizontal-line {
    width: 7%;
    height: 2.5px;
    background-size: 1px 2px, 1px 2px, 10px 10px;
    background-position: 0px 1px, 0px 1px, 9px 5px;
    background-repeat: no-repeat;
    margin: 20px 0;
}

.section_slogan {

    width: 100%;
    font-family: 'Orbitron';
    line-height: 45px;


}

.section_content {

    font-size: 1rem  !important;
    line-height: 30px !important;
    width: 100% !important;
    min-width: 300px;
    max-width: 800px;
    font-family: 'Roboto';
    font-weight: 600;
    /* padding-left: 75px;
    padding-right: 75px; */

  
}


.section_learn_more{
    color: white;
    background: linear-gradient(to right, rgba(38, 6, 68, 0.522), rgba(0, 0, 0, 0.501));
    background-size: 0% ;
    background-position: 1% ;
    background-repeat: no-repeat ;
    /* margin-left: 87% ;
    margin-top: -25px ; */
   
    width: 300px ;
    border-radius: 30px ;
    border: 1px solid white ; 
    padding: 1vmax ;
    transition: all 0.5s ease-out ;
    font-family: 'Roboto' ;
    font-size: 0.9rem  !important;
    font-weight: bold;
    margin: 20px;
    
}
.section_learn_more:hover{
    background-size: 100%;
    font-weight: bolder;
    /* border: 0; */
    border: 0.25px solid rgb(190, 190, 190,0.7) ; 



}
.section_learn_more_480px {
    width: 200px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 0.8rem !important;

    color: white;
    background: radial-gradient(circle, rgba(61, 6, 97, 0.822), rgba(128, 0, 128, 0.801)) ;
    background-size: 0% ;
    background-position: 1% ;
    background-repeat: no-repeat ;
    border-radius: 30px;
    border: 1px solid white;
    display: none;
}


.section_learn_more_480px:hover{
    background-size: 100%;
    font-weight: bold;
    border: 0;


}
.catgs_bar {

    margin-top: 40px;
    display: flex;
    font-size: smaller;
    gap: 0.5vw;
}

.catg{
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    font-family: 'Roboto';
    font-weight: bold;
    color:white;

}

.catg:hover{
   
    color: rgb(119, 2, 2) !important;
}

.catg:focus{
   
    color: rgb(119, 2, 2) !important;
}

.catg:active{
   
    color: rgb(119, 2, 2) !important;
}

.portfolio-projects {

   display: flex;
   width: 100%;
   gap: 15px;
   margin-top: 50px;
}

.portfolio-projects-column1{

    display: flex;
    flex-direction: column;
    gap: 15px;
}


.portfolio-projects-column2{

    display: flex;
    flex-direction: column;
    gap: 15px;
}



.portfolio-projects-item{

    width: 100%;
    transition:200ms ;
    cursor: pointer;
    opacity: 0.8;
}

.portfolio-projects-item:hover{

  
      opacity: 1;
}

.dark{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.445);
    z-index: 999;
    top: 0;
    left: 0;
  
}

.Project{

    width: 60%;
    height: 95vh;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    position: fixed; 
    overflow-y: auto; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    text-align: left;

    
}


.project-image{

    width:fill;
}

.project-info{

    margin-left: 3%;
    margin-top: 5vh !important;

}

.project-about{

    width: 100%;
    font-size: 0.8rem;
    line-height: 23px;
    margin-bottom: 5vh !important;
    font-family: 'Roboto';

}

.project-keywords{

    width: 100%;
    font-size: 0.8rem;
    line-height: 2ch;
    font-family: 'Roboto';

}

.project-title{


    width: 100%;
    background-color: #000000 !important;
    font-size: 1.3rem;
    font-family: 'Orbitron';
    text-align: center;
    color: white !important;
    position: relative;
    padding: 9px 0 9px 0;
    margin-top: 10vh !important;
}

.previewActive{
    filter: brightness(0.8) opacity(0.8);
  }


  @media screen and (min-width: 768px) and (max-width: 1024px) {

   
    .section_slogan {
        width: 100%;
    }  

    .section_content {
        width: 70%;
        margin-top: 8px;
        }    

    

    .section_learn_more{
        /* width: 170px;
        padding-top: 13px;
        padding-bottom: 13px;
        margin-left: auto; */
    }
}  

@media screen and (min-width: 480px) and (max-width: 768px) {


    .Portfolio {
        /* align-items: center; */
        height: calc(fit-content + 10ch)
    }


    .section_slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .section_content {
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 17px;
        width: 65%;
        /* margin-top: -5px; */

    }


    .section_learn_more{
        /* width: 130px;
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 0.65rem;
        margin-top: -20px;
        margin-left: auto; */
    }
    .catgs_bar{

        margin-top: 20px;
    }
    .catg{

        font-size: 0.65rem;
    }

    .portfolio-projects{
        margin-top: 40px;
    }

    .Project{
        width: 90%;
        height: 80vh;
     }
     .project-about{
        width: 80%;
     }
}


@media screen and (max-width: 560px) {

    .catgs_bar{

        margin-top: 40px;
        gap: 30px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-items: left;
       margin-left: 4px;
 
    }
    .catg{

       font-size: 0.7rem;
      
    }
    
    .catgs-bar-dash{
        display: none;
    }
    
}

@media screen and (max-width: 480px) {

    .Portfolio {
        /* align-items: center; */
        height: calc(100% + 10vh);

        gap:8px;
    }


    .section_slogan {

        width: 100%;
        font-size: 1.8rem;
    }
    
    .section_content {
    
        font-size: 0.9rem !important;
        width: 100%;
        margin-top: -2px;
    }
    
    .section_learn_more{
        display: none;
    }
    
    

    .section_learn_more_480px {
        width: 120px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 0.6rem;
        margin-top: 5px;
       display: block;
    }
    

    .portfolio-projects {

       
        margin-top: 30px;
        align-items: center;
        justify-content: center;
     }

     .portfolio-projects-column1{

        width: auto;
        justify-content: center;


     }
     .portfolio-projects-column2{

        width: auto;
        justify-content: center;

     }

     .Project{
        width: 90%;
        height: 80vh;
     }
     .project-about{
        width: 100%;
     }

}




