.Story {

    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    
}

.story-title {
    font-weight: 900;
    font-family: 'Orbitron';
}


.story-content {
    margin: 25px;
    display: flex;
    width: 90%;
    height: 80ch;
    font-family: 'Roboto';

}

.left-content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    width: 100%;
    height: auto;
    justify-items: center;
    font-size: 0.7rem;
    line-height: 20px;
    margin-top: -1vh;

}

.combine {
    display: none;
}



.left-content p {

    width: 80%;
    text-align: center;
    height: fit-content;
}


.right-content p {

    width: 80%;
    text-align: center;
    height: fit-content;

}


.right-content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    width: 100%;
    height: auto;
    justify-items: center;
    font-size: 0.7rem;
    line-height: 20px;
    gap: 5%;
    margin-top: -0vh;
}


.story-grid {

    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    width: 100%;
    justify-items: center;
    height: 100%;
}

.story-grid :nth-child(odd) {

    border-right: solid 3px rgb(111, 36, 182);

    text-align: left;
    width: 100%;
    
    height: 100%;
    margin-left: auto;
}

.story-grid :nth-child(even) {

    border-left: solid 3px rgb(111, 36, 182);

    text-align: right;
    width: 100%;
    height: 100%;
    margin-right: auto;
}

.story-grid :nth-child(4n+1) {

    border-top: dashed 2px rgb(211, 43, 226);
    border-right: solid 3px rgb(111, 36, 182);


    width: 100%;
    height: 100%;
    margin-left: auto;
}

.story-grid :nth-child(4n+2) {

    border-bottom: dashed 2px rgb(211, 43, 226);
    border-left: solid 3px rgb(111, 36, 182);

    width: 100%;
    height: 100%;
    margin-right: auto;
}


.story-grid :last-child {

    border-right: none;
    border-left: none;
}






@media screen and (min-width: 768px) and (max-width: 3200px) {

    .second {
        margin-top: 110px !important;
    }

    .fourth {
        margin-top: 115px !important;
    }

    .sixth {
        margin-top: 100px !important;
    }

   

}

@media screen and (min-width: 480px) and (max-width: 768px) {

    .story-title {
        font-size: 1.3rem;
    }

    .left-content p {

        font-size: 0.6rem;
        width: 90%;
    }

    .right-content p {

        font-size: 0.6rem;
        width: 90%;

    }

    .second {
        margin-top: 110px !important;
    }

    .fourth {
        margin-top: 115px !important;
    }

    .sixth {
        margin-top: 100px !important;
    }



}

@media screen and (max-width: 480px) {


     .story-grid{
        width: 40%;
      
     }
     

    .story-title {
        font-size: 1.3rem;
    }

    .left-content p {

        font-size: 0.6rem;
        text-align: left;
    }

    .right-content {
        display: none;
    }


    .story-grid :nth-child(4n+1) {

        border-top: dashed 2px rgb(211, 43, 226);
        border-right: solid 3px rgb(111, 36, 182);
        border-bottom: dashed 2px rgb(211, 43, 226);



        width: 100%;
        height: 100%;
        margin-left: auto;
    }

    .story-grid :nth-child(4n+2) {

        border: none;
        width: 0;
        height: 0;
        margin-right: 0;
    }

    .combine{
        display: block;
    }

}