html {
  position: relative;
}

body {
  margin: 0;
}




.Footer{

    display: flex;
    align-items: center;
    justify-items: flex-start;
    padding-top: 35px;
    padding-bottom: 35px;
    font-size: 0.8rem;
    border-top: solid 1px white;

    position: relative;
    bottom: 0;
    width: 100%;
    font-family: 'Roboto' !important;
    font-weight: 600;

}

.Footer p{
  margin-left: 5vw;
}

.footer-links{
 

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 5vw ;
    width: 50%;
    gap: 2%;
    position: absolute;
    right: 0;    


}




@media screen and (min-width: 768px) and (max-width: 1024px) {

  .Footer{
    font-size: 0.7rem;
  
  }
  

  
}

@media screen and (min-width: 600px) and (max-width: 768px) {


  .Footer{
    font-size: 0.65rem;
   
  }
  
  .footer-links{
  width: 100%;
  
  }
}

@media screen and (max-width: 600px) {

     .Footer{
      flex-direction: column !important;
      align-items: center; 
      gap: 5ch;
      height: 12ch;
      padding-top: 10px;
      padding-bottom: 10px;
    }

     .Footer p{
      margin-top: 29px;

      position: absolute;
      top: 0;
      font-size: 0.78rem;
      text-align: center;
      margin-left: 0; 

     }
     
     .footer-links{
      height: 15ch;
      display: flex;
      width: 100%;

      margin: 0;
      position: relative;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      justify-content: center;
      gap: 20px;
      margin-top: -30px;
     }
   
     .footer-links a{
  
       text-align: center;
       font-size: 0.78rem;


     }
     .footer-space{

      display: none;
     }

}





@media screen and (max-width: 480px) {




  .footer-links a{

    text-align: center;
    font-size: 0.65rem;


  }
  .footer-space{

   display: none;
  }

}