.Header {

    display: flex;
    align-items: center;
    gap: 100px;
    padding: 5vw;
    padding-bottom: 2.5vw;
    position: relative;
    font-weight: bold !important;
    color: white;
    

}

nav {

    background-color: transparent;
    width: 100%;
    padding: 0;

}

nav ul {
    width: 100%;
    list-style: none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 !important;
    gap: 2%;
}

nav li {

    height: 50px;    
    font-size: 0.9rem;
}


nav a {

    height: 100%;
    padding: 0 30px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-family: 'Orbitron';
}

nav .dropdown {
    height: 100%;
    padding: 0 30px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-family: 'Orbitron';
}

nav a:hover {

    color: linear-gradient(to right, blueviolet, purple);
}

nav li:first-child {

   font-size: larger;
   position: relative;
   margin-right: auto;
}

nav li:first-child a{


    padding: 0 !important;
     
}

nav ul .navbar-icons{

    display: flex;
    gap: 30%;
    margin-right: auto;
    margin-left: 0%;

}

nav ul .navbar-icons li a{

    padding: 0;

}

nav .hideOnDesktop a{
   padding: 0;
   text-align: right;

}


.sideBar{

    position: fixed;

    top: -5ch;
    right: 0;
    height: 150vh;
    width: 100%;
    z-index: 98 !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

   
}


.sideBar-dropdown{
    z-index: 98;
    background-color: white;
}


.sideBar li svg:hover{

    fill: rgb(107, 1, 54);
}

.sideBar li{
    width: 100%;
}

.sideBar a{
    width: 100%;
    font-family: 'Orbitron';

    
}
.sideBar svg{
    padding: 0 25px;
}


.navBar {

    display: flex;
    font-size: 0.9rem;
    gap: 6vw;
    font-family: 'Orbitron';


}

a {
    text-decoration: none;
}


.small-gap {

    display: flex;
    gap: 3.5vw;
    position: relative;
    right: 2vw;


}

nav .auth {
   color: rgb(255, 255, 255);
   border-radius: 28px;
   border: 1px solid rgb(255, 255, 255);
   padding-left: 30px !important;
   padding-right: 30px !important;
   text-align: center !important;
   height: 70%;
  background-color: transparent;
  cursor: pointer;
  width: 130px;
  font-family: 'Orbitron';
  font-weight: bold;


}

.sideBar .auth{

    color: rgb(255, 255, 255);
    /* border-radius: 28px; */
    border: 1px solid rgb(255, 255, 255);
    /* border: none !important; */
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center !important;
    height: 70%;
   background-color: transparent;
   cursor: pointer;
   width: 160px !important;
   font-size: 0.9rem;
   margin-left: 0px;
   font-family: 'Orbitron';
   font-weight: bold;


}


.dropdown {
   
    display: flex;
    position: relative;
    height: 100%;
    font-family: title;
    align-items: center;
}

.dropbtn {
    background-color: inherit;
    color: inherit;
    padding: 0;
    font-size: 1em;
   
    border: none;
    cursor: pointer;
    font-family: 'Orbitron';
    font-weight: bold;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 0;
    left: 15px;
    margin-top: 52px;
    background-color: rgb(rgb(43, 27, 11));
    min-width: 160px;
    z-index: 1;
    border-radius: 10px;
}

.dropdown-content a {
    color: inherit;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;

}

.dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #111111 !important;
}





.sideBar-dropdown {
   
    display: flex;
    position: relative;
    height: 100%;
    font-family: 'Orbitron';
    font-weight: bold;
    align-items: center;
    background-color: transparent !important;
}

.sideBar-dropbtn {
    background-color: inherit;
    color: inherit;
    padding: 0;
    font-size: 1em;
    font-family: 'Orbitron';
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin-left: 30px;
}

.sideBar-dropdown-content {
    display: none;
    position: absolute;
    top: 0;
    left: 15px;
    margin-top: 52px;
    min-width: 160px;
    z-index: 1;
    border-radius: 10px;
    font-family: 'Orbitron';
    font-weight: bold;
    background-color: black;

}

.sideBar-dropdown-content a {
    color: inherit;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;

}

.sideBar-dropbtn svg{
    margin-left: -15px;
}

.sideBar-dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #111111 !important;
}




@media(max-width:1299px){

    .hideOnMobile{
        display: none;
    }

    .dropbtn{
        display: none;
    }
    
}

@media(max-width:400px){

    .sideBar{
        width: 100%;
    }
}


@media(min-width:1300px){

    .hideOnDesktop{
        display: none;
    }
 
}


@media screen and (max-width: 480px) {

    .Header {

        display: flex;
        align-items: center;
        gap: 12vw;
        padding: 5vw;
        position: relative;
        
    
    }
    
    nav {
    
        background-color: transparent;
        width: 100%;
        padding: 0;
    
    }
    
    nav ul {
        width: 100%;
        list-style: none;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 0 !important;
        gap: 2%;
    }
    
    nav li {
    
        height: 50px;    
        font-size: 0.9rem;
    }
    
    
    nav a {
    
        height: 100%;
        padding: 0 30px;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-family: sans-serif;
    }
    
      
      
      
    
      
    
    nav a:hover {
    
        color: linear-gradient(to right, blueviolet, purple);
    }
    
    nav li:first-child {
    
       font-size: larger;
       position: relative;
       margin-right: auto;
    }
    
    nav li:first-child a{
    
    
        padding: 0 !important;
         
    }
    
    nav ul .navbar-icons{
    
        display: flex;
        gap: 40%;
        margin-right: auto;
        margin-left: 2%;
    
    }
    
    nav ul .navbar-icons li a{
    
        padding: 0;
    
    }
    
    nav .hideOnDesktop a{
       padding: 0;
       text-align: right;
    
    }
    
    
    .sideBar{
    
        /* position: fixed;
    
        top: 0;
        margin-top: -0.2ch;
        right: 0;
        height: 100vh;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; */
    }
    
    
    
    .sideBar li svg:hover{
    
        fill: rgb(107, 1, 54);
    }
    
    .sideBar li{
        width: 100%;
    }
    
    .sideBar a{
        width: 100%;
        
    }
    .sideBar svg{
        padding: 0 25px;
    }
    
    
    .navBar {
    
        display: flex;
        font-size: 0.9rem;
        gap: 6vw;
    
    }
    
    a {
        text-decoration: none;
    }
    
    
    .small-gap {
    
        display: flex;
        gap: 3.5vw;
        position: relative;
        right: 2vw;
    
    
    }
    
    .auth {
        
    
    }
    
}
