
.Services_header{


    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 50px;
    padding-top: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}



.Services_info{
    display: flex;
    margin-top: 30px;
    gap: 20px;
    text-align: left;

  
}



.services_slogan {

    width: 50%;
}

.services_content {

    font-size: 0.7rem;
    line-height: 24px;
    width: 50%;
    margin-top: 20px;
}


.services_col_1{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.services_col_2{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}


.service{
    background-color: #1313139d;
    padding: 40px 30px 40px 30px;
    font-size: 0.8rem;
    border-radius: 20px;    
    display: flex;
    flex-direction: column;
}


.service_logo {

    background-color: rgb(88, 27, 158);
    color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 20px;
    font-size: 50px;

}


.service_title{
    font-weight: 700;
    margin-top: 30px;
    font-family: 'Orbitron';

}

.service_content{
    line-height: 20px;
    font-size: 0.7rem;
    font-family: 'Roboto';
}


.service_icons{
    display: grid;
    justify-items: space-between;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
    margin-top: 30px;
    margin-bottom: -15px;
}

.service_icons_special{

    display: grid;
    justify-items: space-between;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
    margin-top: 30px;
    margin-bottom: -15px;

}

.service_object{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

}

.service_object_name{
    font-size: 0.7rem;
    line-height: 20px;
    font-family: 'Roboto';
}

.serivce_icon{

    background-color: rgb(240, 240, 240);
    color: rgb(199, 199, 199);
    border-radius: 10px;
    padding: 20px;
    font-size: 40px;

}

















@media screen and (min-width: 950px) and (max-width: 1024px) {


    .services_content {
        width: 70%;
        margin-top: 8px;
    }

    

}

@media screen and (min-width: 750px) and (max-width: 950px) {


    .services_slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .services_content {
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 17px;
        width: 65%;
        margin-top: -5px;

    }

    .service_icons{
        grid-template-columns: auto auto auto ;
        grid-template-rows: auto auto ;
    }

    .service_icons_special{

        grid-template-columns: auto   ;
        grid-template-rows: auto  ;

        gap: 30px;
    }
   
    

}


@media screen and (max-width: 750px) {

   


    .services_slogan {

        width: 100%;
        font-size: 1.8rem;
    }
    
    .services_content {
    
        font-size: 0.6rem;
        line-height: 15px;
        width: 100%;
        margin-top: -1vh;
    }
    
    .Services_info{
        flex-direction: column;
    }

    .service_icons{
        grid-template-columns: auto auto auto  ;
        grid-template-rows: auto auto ;
    }

    .service_icons_special{

        grid-template-columns: auto   ;
        grid-template-rows: auto  ;

        gap: 30px;
    }

   

}