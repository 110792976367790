.Text_to_Image {
    width: 100%;
    padding-bottom: 50vh;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-transform: capitalize !important;
   
  }

  .text_to_image_title{
    color: white;
    font-size: 2.5rem;
    font-family: 'Orbitron';

    
  }
  
  .ai-img{
  
  opacity: 0.8;
  transition: 200ms;
  }
  
  .ai-img:hover{
  
    opacity: 1;
  }
  
  .view {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 97%; /* Adjust the maximum width as needed */
    max-height: 97%; /* Adjust the maximum height as needed */
    object-fit: contain;
    filter: brightness(100%);
    border: 0;
    border-radius: 0 !important;
    cursor: zoom-out;
  }
  
  
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: rgb(224, 236, 234);
    letter-spacing: 7px;
    gap: 3%;
    width: 100%;
    height: auto;
    min-height: 100%;
    filter: brightness(105%);
  }
  
  

  form{
  
    display: flex;
    margin-top:20px ;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.5vw;
    gap: 4ch;
    width: max-content;
  }
  
  .inputs{
  
    display: flex;
    justify-content: center;
    max-width: 100vw;
    min-width: 90vw;
    gap: 1.7ch;  
    margin-left: 8%;
  }
  
  .prompt-input{
  
    width: 40%;
    min-width: 30vw !important;
    height: auto;
    padding: 1ch;
    outline: none;
    border-radius: 11px;
    border: 1px solid rgba(0, 0, 0, 0);
    padding-left: 2ch;
    background-color: rgba(255, 255, 255, 0.750);
    font-size: 1em;
    opacity: 0.87;
    font-family: 'Roboto';

  }
  
  .preset{
  
    display: flex;
    justify-content: center;
    width: 90% !important;
    gap: 0.7ch;
    margin-left: 3.1vw;
    margin-top: 5px;
  }
  
  label{
    
    font-weight: 500;
    font-size: 1.2em;
    color:#ffffffcb !important;
    letter-spacing: 5px !important;
  }
  
  .preset-select{
  
   width: auto;
   max-width: 50%;
   position: relative;
   border-radius: 14px;
   border: 1px solid rgba(0, 0, 0, 0);
   top: 0.1ch;
   outline: none;
  
   background-color: rgba(255, 255, 255, 0.616);
   font-size: 0.9em;
   opacity: 0.87;
   margin-right: 1%;
   text-align: center !important;
   padding: 0.5%;
   color: black !important;
   font-family: 'Roboto';
   cursor: pointer;

  }

  .preset-select:hover{

    background-color: rgba(255, 255, 255, 0.590);


  }
  
  

  .generate{
  
    margin-bottom: 5ch;
    margin-right: 0 !important;
    padding: 10px;
    position: relative;
    border-radius: 20px;
    outline: none;
    border: 0;
    background: linear-gradient(to right, rgba(98, 15, 177, 0.522), rgba(216, 6, 164, 0.501));
    margin: 0;
    cursor: pointer;
    color: rgb(255, 255, 255);
    letter-spacing: 0.5px;
    font-size: 0.75em;
    /* font-family: 'Orbitron'; */
    width: 100px;
    right: 0;

  }
  
  .generate:hover{
  
    background-color: black;
  
  }
  
  .cancel{
  
    margin-bottom: 5ch;
    margin-right: 0 !important;
    padding: 10px;
    position: relative;
    border-radius: 20px;
    outline: none;
    border: 0;
    background: linear-gradient(to right, rgba(226, 43, 113, 0.822), rgba(128, 0, 128, 0.801));
    margin: 0;
    cursor: pointer;
    color: rgb(255, 255, 255);
    letter-spacing: 0.5px;
    font-size: 0.75em;
    /* font-family: 'Orbitron'; */
    width: 100px;
    right: 0;
  }
  
  .imgs{
  
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 5ch;
    width: 60%;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    left: 1vw;
  }
  
  .ai-img{
  
  width: 100%;
  border-radius: 10px !important;
  border: solid 2px rgba(0, 0, 0, 0.329);
  min-width: 80%;
  min-height: 80%;
  max-width: 100%; /* Adjust the maximum width as needed */
  max-height: 100%;
  object-fit: fill;
  }


  .dark{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.445);
    z-index: 990;
    top: 0;
    left: 0;
  
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

      

      .prompt-input{
        width: 60%;
      }
      .imgs{
        grid-template-columns: repeat(3,1fr);
        grid-template-rows: repeat(2,1fr);
        width: 90%;

      }

}

  
  @media only screen and (max-width: 768px ){
  

  
    .text_to_image_title{
      font-size:1.85rem;
    }


    form{
      gap: 3ch;
      margin-bottom: 4ch;
    }
    .inputs{
      gap: 1.3ch;  
      margin-left: 9%;
    }
    .prompt-input{
  
      font-size: 0.8em;
      width: 60%;
    }
    label{
    
      font-size: 0.9em;
    }
    .preset-select{
      font-size: 0.8em;
      width: 20%;
      text-align: center !important;
  
    }
    .generate{
  
      font-size: 0.6em;
      width: 70px;
  
    }
    .cancel{
  
      font-size: 0.6em;
      width: 70px;
  
    }
    .imgs{
  
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-template-rows: repeat(2,1fr);
      justify-content: space-between;
      width: 80%;
      gap: 20px;
    }
    .view{
      top: 50%;
      left: 50%;
    }
  
    .preset{
  
      margin-left: 0vw;
    }
  }
  
  @media only screen and (max-width: 480px){
  
    
    .text_to_image_title{
      font-size:1.7rem;
    }
   
    form{
      gap: 3ch;
      margin-bottom: 3ch;
    }
    .inputs{
      gap: 20px;  
     align-items: center;
     margin-left:0 ;
     margin-right: 0;
     width: 100%;
     flex-direction: column;
    }
    .prompt-input{
  
      font-size: 0.7em;
      width: 90%;
    }
    label{
    
      font-size: 0.7em;
    }
.preset{
  gap: 10px;
  margin-top: -50px;
}

    .preset-select{
      font-size: 0.7em;
      /* text-align: left !important; */
      max-width: 50%;
      width: 50%;
    }
    .generate{
  
      font-size: 0.6em;
      width: 130px;
      position: relative;
      top: 55px;
  
    }

    .cancel{
        
      font-size: 0.6em;
      width: 130px;
      position: relative;
      top: 55px;
  
    }
    .imgs{
  
      display: grid;
      grid-template-columns: repeat(1,1fr);
      grid-template-rows: repeat(6,1fr);
      gap: 3ch;
      width: 90%;
      margin-top: 75px;
    }
    .view{
      top: 50%;
      left: 50%;
    }
    .preset{
  
      margin-left: 0.5vw;
    }
  }
  
  
  @media only screen and (max-height: 480px){
  
  
  
    .view{
      top: 50%;
      left: 50%;
    }
  }