.Services {

   display: flex;
    flex-direction: column;
    padding: 5vw;
    position: relative;
    height: calc(fit-content + 10ch);
    padding-bottom: 200px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.title {
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    gap: 2.5%;
}


.horizontal-line {
    width: 7%;
    height: 2.5px;
    background-size: 1px 2px, 1px 2px, 10px 10px;
    background-position: 0px 1px, 0px 1px, 9px 5px;
    background-repeat: no-repeat;
    margin: 20px 0;
}

.services-slogan {

    width: 50%;
    font-family: 'Orbitron';

}

.services-content {

    font-size: 0.9rem !important;
    line-height: 27px !important;
    width: 65%;
    max-width: 800px;
    font-family: 'Roboto';
}


.services-learn-more {
    color: white;
    background: radial-gradient(circle, rgba(61, 6, 97, 0.822), rgba(128, 0, 128, 0.801));
    background-size: 0% ;
    background-position: 1%;
    background-repeat: no-repeat;
    margin-left: 87%;
    margin-top: -25px;
    width: auto;
    border-radius: 30px;
    border: 1px solid white;
    padding: 1vw;
    transition: all 0.5s ease-out;
    font-family: 'Roboto';
}

.services-learn-more:hover{
    width: 170px;
    background-size: 100%;
    font-weight: bold;
    border: 0;


}

.services-learn-more-480px {
    width: 110px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.55rem;
    font-family: 'Roboto';


    color: white;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    border-radius: 30px;
    border: 0;
    display: none;
}

.services-cards {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    gap: 15px;
    row-gap: 70px !important;
}

.service-card {

    display: flex;
    flex-direction: column;
    /* background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801)); */
    background-color: transparent;
    opacity: 85%;
    width: 100% !important;
    height: 350px !important;
    max-width: 400px;
    min-width: 20vmin;
    border: 1px solid white;
    border-style: groove;
    border-radius: 29px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.service-card-logo {

    border-radius: 10px;
    position: relative;
    width: 60px;
    z-index:1 ;
    padding-left: 8vw;
    padding-right: 8vw;
    margin-top: -20px;
}

.service-card-title {
    font-size: 1.1rem;
    font-weight: bold;
    font-family: 'Orbitron' ;


}

.service-card-content {
    font-size: 0.8rem !important;
    text-align: center;
    line-height: 20px;
    margin-top: -0.4vh;
    width: 90%;
    font-family: 'Roboto';
    font-weight: 900 !important;
    word-wrap:break-word !important;



}

@media screen and (min-width: 768px) and (max-width: 1024px) {


   

    .services-content {
        width: 85%;
        margin-top: 8px;
    }

    .services-learn-more {
        width: 170px;
        padding-top: 13px;
        padding-bottom: 13px;
        margin-left: auto;
    }

    .services-cards {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;      
        width: 100%;
        margin-top: 100px;
        justify-items: center;


    }

    .service-card {

        width: 100%;


    }
    .service-card-logo{
        padding-left: 160px;
        padding-right: 160px;
    }

    .service-card-title {
        font-size: 1rem;
        font-weight: 600;
    }

    .service-card-content {
        font-size: 0.8rem;
        line-height: 22px;

    }



}

@media screen and (min-width: 480px) and (max-width: 768px) {


    


    .services-slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .services-content {
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 17px;
        width: 100%;
        margin-top: -5px;


    }


    .services-learn-more {
        width: 130px;
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 0.65rem;
        margin-top: -20px;
        margin-left: auto;
    }


    .services-cards {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
      
        row-gap: 10%;
        width: 100%;
        margin-top: 70px;
        justify-items: center;


    }

    .service-card {

        height: 35ch;
        min-width: 40vw;


    }

    .service-card-logo {
        width: 50px !important;
    }

    .service-card-title {
        font-size: 0.9rem;
        font-weight: 600;
    }

    .service-card-content {
        font-size: 0.7rem;
        line-height: 20px;
        width: 70%;
        padding-bottom: 5vw;
        
    }

}

@media screen and (max-width: 600px){


   

    .services-cards {
        display: grid;
        grid-template-columns:  auto;
        grid-template-rows:  auto;
        gap: 0%;
        column-gap: 5%;
        row-gap: 5%;
        width: 100%;
        margin-top: 70px;
        justify-items: center;
       


    }

    .service-card {

       

    }

    .service-card-logo {
       width: 50px !important;
       padding-left: 40dvw;
       padding-right: 40dvw;
    }

    .service-card-title {
        font-size: 1.1rem;
        font-weight: 600;
    }

    .service-card-content {
        font-size: 0.7rem;
        line-height: 20px;
        width: 90%;
        padding-bottom: 5vw;

    }


}



@media screen and (max-width: 480px){


    .services-cards {
        display: grid;
        grid-template-columns:  auto;
        grid-template-rows:  auto;
        gap: 0%;
        column-gap: 5%;
        row-gap: 5%;
        width: 100%;
        justify-items: center;


    }

    .service-card {

   
        width: 90%;

    }

    .service-card-logo {
        padding-left: 40dvw;
        padding-right: 40dvw;
    }

    .service-card-title {
        font-size: 1rem;
        font-weight: 600;
    }

    .service-card-content {
        font-size: 0.8rem;
        line-height: 24px;
        width: 90%;
        padding-bottom: 5vw;

    }


}


@media screen and (max-width: 480px) {

    .Services {
        /* align-items: center;
        height:270ch;
        gap:8px;
        padding-bottom: 300px; */

    }

    .service-card{
     width: 90vmin !important;

    }

    .services-slogan {

        width: 100%;
        font-size: 1.8rem;
    }
    
    
    .services-content {
    
        font-size: 0.6rem;
        line-height: 15px;
        width: 100%;
        margin-top: -1vh;
    }
    

    .services-learn-more {
        display: none;
    }

    .services-learn-more-480px {
        width: 120px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 0.6rem;
        margin-top: 5px;
       display: block;
    }


   

}