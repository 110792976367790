.Newsletter {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    /* background-color: #111111; */
    color: white;
}

.title {
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    gap: 2.5%;
}


.horizontal-line {
    width: 7%;
    height: 2.5px;
    background-size: 1px 2px, 1px 2px, 10px 10px;
    background-position: 0px 1px, 0px 1px, 9px 5px;
    background-repeat: no-repeat;
    margin: 20px 0;
}

.newsletter-slogan {

    width: 100%;
}

.newsletter-content {

    font-size: 0.8rem;
    color: rgb(209, 209, 209) !important;
    line-height: 3.1vh;
    width: 70%;
}


.newsletter-form {

    display: flex;
    gap: 3%;
    width: 100%;
    position: relative;
    margin-top: 10px;
}

.newsletter-form-input {

    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 3vh;
    width: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
}

.newsletter-suscribe-button {
    color: white;
    border-radius: 30px;
    width: 150px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    border: 1px white solid;
    position: absolute;
    bottom: 0;
    margin-left: 400px;
    font-family: 'Roboto';
    background: linear-gradient(to right, rgba(38, 6, 68, 0.522), rgba(0, 0, 0, 0.501));
    background-size: 0% ;
    background-position: 1% ;
    background-repeat: no-repeat ;    border-radius: 30px;
    transition: all 0.5s ease-out ;
    font-size: 0.9rem  !important;
    font-weight: bold;
}
.newsletter-suscribe-button:hover{
    background-size: 100%;
    font-weight: bold;
    /* border: 0; */
    border: 0.25px solid rgb(190, 190, 190,0.7) ; 
}


.newsletter-suscribe-button-480px {
    color: white;
    background: linear-gradient(to right, rgba(38, 6, 68, 0.522), rgba(0, 0, 0, 0.501));
    background-size: 0% ;
    background-position: 1% ;
    background-repeat: no-repeat ;    border-radius: 30px;
    transition: all 0.5s ease-out ;
    border-radius: 30px;
    border: 1px white solid;
    width: 130px;
    padding-top: 13px;
    padding-bottom: 16px;
    text-align: center;
    display: none;
    font-family: 'Roboto';
    transition: all 0.5s ease-out;
    font-size: 0.9rem  !important;
    font-weight: bold;

}

.newsletter-suscribe-button-480px:hover{

    background-size: 100%;
    font-weight: bold;
    /* border: 0; */
    border: 0.25px solid rgb(190, 190, 190,0.7) ; 
}


@media screen and (min-width: 768px) and (max-width: 1024px) {}

@media screen and (min-width: 480px) and (max-width: 768px) {


    .Newsletter {
        /* align-items: center; */
        height: calc(fit-content + 10ch)
    }


    .newsletter-slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .newsletter-content {
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 15px;
        width: 65%;

        margin-top: 5px;

    }


    .newsletter-suscribe-button {
        width: 130px;
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 0.7rem;
        margin: 0 !important;
        margin-left: 280px !important;

    }

    .newsletter-form-input {

        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 3vh;
        width: 210px;
    }


}

@media screen and (max-width: 480px) {


    .newsletter-slogan {
        width: 100%;
        font-size: 1.8rem
    }

    .newsletter-content {
        width: 100%;
        font-size: 0.6rem;
        line-height: 15px;
    }

    .newsletter-form-input {

        width: 100%;
    }

    .newsletter-suscribe-button {
        display: none;
    }

    .newsletter-suscribe-button-480px {
        display: block;
        border: 0;
        outline: none;
        position: relative;
        left: 25%;
        margin-top: 40px;
        font-size: 0.65rem;
        font-weight: bold;
        width: 50%;
        border: 1px white solid;

    }

}