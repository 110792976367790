.Main_1 {


    display: flex;
    padding: 5vw;
    padding-bottom: 100px;
    margin-top: 100px;
    /* background-color: #111111; */
    color: white;
    gap: 5px;

    

}
.astro{
    /* background-image: url(''); */
    animation:moving_astro 15s ease-in-out infinite;
    overflow: hidden;
}


@keyframes moving_astro{
    50%{
        margin-top: 60px;
        margin-right: 20px;
    }
  
}

.social-links {

    display: flex;
    flex-direction: column;
    gap: 60px;
    /* border: solid white 1px; */

}

.fa {
    text-decoration: none;
    color: white !important;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
    text-align: center;
}

.social-icon {

    font-size: 28px;

}


.marketing {

    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin-left: -20px;

}


.marketing-title {
    font-size: 2.5rem;
    margin-top: 4vh;
    line-height: 80px;
    font-family: 'Orbitron' !important;
    font-weight: bolder;
    text-shadow: 3px 3px 2px rgb(14, 13, 13),
             0px 1px 15px rgb(0, 0, 0)
}

.message {

    margin-top: 1.5% !important;
    width: 100%;
    font-size: 0.7rem;
    color: rgb(209, 209, 209) !important;
    line-height: 20px;
    text-align: center;
}

.contact-us-button {

    margin-top: 10px;
    padding: 15px;
    padding-left: 80px;
    padding-right: 80px;
    width: auto;
    text-align: center;
    border: 1px solid  white;;
    color: white;
    cursor: pointer;
    font-family: 'Orbitron';
    font-weight: bold;

    background: linear-gradient(to right, rgba(38, 6, 68, 0.522), rgba(0, 0, 0, 0.501));
    background-size: 0% ;
    background-position: 1% ;
    background-repeat: no-repeat ;    border-radius: 30px;
    transition: all 0.5s ease-out ;
}

.contact-us-button:hover{
    background-size: 100%;
    font-weight: bold;
    /* border: 0; */
    border: 0.25px solid rgb(190, 190, 190,0.7) ; 



}


.glow {
    color: #fff;
    text-align: center;
    
    transition: all 2s ease-in-out;
    text-shadow: 0 0 0 #fff, 0 0 0 #fff, 0 0 0 #fff, 0 0 0 #fff, 0 0 0 #fff, 0 0 0 #fff, 0 0 0 #fff;

  }
  
  .glow:hover{
   
    
      text-shadow: 0 0 20px #fff, 0 0 30px #ff7f4d, 0 0 40px #ff8e4d, 0 0 50px #ffe134, 0 0 60px #2289ce, 0 0 70px #6f09b3, 0 0 80px #660707;

  }



@media screen and (min-width: 768px) and (max-width: 1024px) {

    .Main_1 {
        margin-top: 10vh;
    }

    .marketing {
        width: 100%;
        margin-left: 0%;
        margin-top: 0;
    }

    .marketing-title {
        font-size: 1.8rem;
    }

    .message {
        width: 80%;
        margin-top: 3%;
        font-size: 0.7rem;
    }

    .contact-us-button {

        padding-left: 60px;
        padding-right: 60px;
        margin-top: 10px;


    }

    .social-icon {

        font-size: 25px;

    }

}

@media screen and (min-width: 480px) and (max-width: 768px) {


    .Main_1 {
        margin-top: 10vh;
    }

    .marketing {
        width: 100%;
        margin-left: 0%;
        margin-top: 0;
    }

    .marketing-title {
        font-size: 1.8rem;
        line-height: 47px;
    }

    .message {
        width: 80%;
        margin-top: 3%;
        font-size: 0.5rem;
        line-height: 14px;
    }

    .contact-us-button {

        padding: 10px;
        width: 150px;
        font-size: 0.7rem;

    }

    .social-icon {

        font-size: 20px;

    }

    .social-links {
        gap: 50px;
    }




}





@media screen and (max-width: 480px) {

    .Main_1 {
        margin-top: 10vh;
    }

    .marketing {
        width: 100%;
        margin-left: 0%;
        margin-top: 0;
    }

    .marketing-title {
        font-size: 1.45rem;
        line-height: 40px;
    }

    .message {
        width: 90%;
        margin-top: 3%;
        font-size: 0.5rem;
        line-height: 14px;
    }

    .contact-us-button {

        padding: 10px;
        width: 150px;
        font-size: 0.7rem;
        margin-top: 10px;

    }

    .social-icon {

        font-size: 20px;

    }

    .social-links {
        position: absolute;
        top:400px;
        width: 90%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 100px;



    }


    


}
