.Careers {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    position: relative;
    height: calc(fit-content + 10ch);
    padding-bottom: 200px;
    color: white !important;
  

}

.centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.careers-slogan {

    width: 100%;

}




.careers-catgs-bar {

    margin-top: 20px;
    display: flex;
    font-size: smaller;
    gap: 0.5vw;
}

.careers-catg{
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    font-weight: 700;
    color: white;
}

.careers-catg:hover {

    color: rgb(119, 2, 2) !important;
}

.careers-catg:focus {

    color: rgb(119, 2, 2) !important;
}

.careers-catg:active {

    color: rgb(119, 2, 2) !important;
}

.careers-jobs {

    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.careers-job {

    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;


}

.job-title {
    font-size:medium;
    font-family: 'Roboto';

    font-weight: 900;
    /* cursor: pointer; */
    width: fit-content;

}

.job-description {

    margin-top: 20px !important;
    font-size: small;
    font-family: 'Roboto';


}

.job-details {
    display: flex;
    margin-top: 20px !important;
    gap: 1%;

}

.job-location {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem !important;
    font-family: 'Roboto';
    opacity: 0.6;
    border: 1px solid rgba(255, 255, 255, 0.555);
    border-radius: 20px;
    padding-right: 15px;
    padding-left: 15px;
    height: 30px;
    gap: 5px;
    
}

.job-type {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem !important;
    font-family: 'Roboto';
    opacity: 0.6;
    border: 1px solid rgba(255, 255, 255, 0.555);
    border-radius: 20px;
    padding-right: 15px;
    padding-left: 15px;
    height: 30px;
    gap: 5px;
}

.job-apply-button {
    font-size: smaller;
    font-family: 'Roboto';
    color: white;
    background-color: rgb(76, 11, 136);
    width: 100px;
    border-radius: 30px;
    border: 0;
    padding: 10px;
    position: absolute;
    top: 30%;
    right: 0;
    

}

.careers-job svg {

    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    width: 15px;
    height: 15px;
    color: rgba(255, 255, 255, 0.5);
}


.dark {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.445);
    z-index: 999;
    top: 0;
    left: 0;

}


.jobForm {

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    padding: 40px;
    border-radius: 20px;
    width: 30%;
    height: 60vh;
    background-color: white;
    color: black;
    position: fixed;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.confirmation-message {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    gap: 20px;
    border-radius: 20px;
    width: auto;
    height: auto;
    background-color: white;
    color: black;
    position: fixed;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}


.confirm-message {
    margin-top: 30px;
    font-size: 0.8rem;
    font-weight: 700;
    font-family: 'Roboto';
    width: 100%;
    text-align: center !important;

}

.continue-button {

    font-size: smaller;
    color: white;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    width: 150px;
    border-radius: 30px;
    border: 0;
    padding: 10px;
    font-family: 'Roboto';

}


.input-title {
    font-size: 0.8rem;
    font-weight: 700;
    font-family: 'Roboto';

}

.jobForm-input {
    width: 80%;
    padding: 0 7.5% 0 7.5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.781);
    outline: 0;
    border-color: rgba(0, 0, 0, 0.13);
    height: 8ch;
    font-size: 0.7rem;
    font-family: 'Roboto';

}

.jobForm-apply-button {

    font-size: smaller;
    font-family: 'Roboto';
    color: white;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    width: 100px;
    border-radius: 30px;
    border: 0;
    padding: 10px;
    margin-left: auto;
}


@media screen and (min-width: 768px) and (max-width: 1024px) {


    .careers-slogan {
        width: 50%;
    }



    .careers-apply-button {
        width: 170px;
        padding-top: 13px;
        padding-bottom: 13px;
        margin-left: auto;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px) {


    .careers-slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.4rem;
    }

    .jobForm {
        width: 50%;
        height: auto;
    }


    .careers-apply-button {
        width: 110px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 0.55rem;
        margin-top: -16px;
        margin-left: auto;
    }

    .careers-catgs-bar{

        margin-top: 20px;
    }
    .careers-catg{

        font-size: 0.65rem;
    }


}

@media screen and (max-width: 520px) {

    .careers-catgs-bar{

        margin-top: 30px;
        gap: 30px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-items: left;
       margin-left: 4px;
 
    }
    .careers-catg{

       font-size: 0.7rem;
      
    }
    
    .catgs-bar-dash{
        display: none;
    }
    
    
}

@media screen and (max-width: 480px) {


    .careers-slogan {

        width: 100%;
    }

    .jobForm {
        width: 70%;
        height: auto;
    }

    .careers-apply-button {
        color: white;
        background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
        margin-left: 87%;
        margin-top: -4vh;
        width: auto;
        border-radius: 30px;
        border: 0;
        padding: 1vw;
    }


    .job-apply-button {

        position: relative;
        margin-top: 25px;
        padding: 7px;

    }

}