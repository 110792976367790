.Post{
   
    padding-left: 5vw;
    padding-right: 5vw;

    height: auto;
    padding-top: 100px;
    padding-bottom: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.blog_slogan {

    width: 100%;
}


.Post p{
    font-family: parag;
}

.post-img{

    width: 100%;
    border-radius: 25px;
    width: auto-fill;
    opacity: 0.8;
}

.post-type{
    margin-top: 20px;
    font-size: 0.75rem;
    font-family: parag;
    opacity: 0.4;
}

.post-date{
    margin-top: 30px;
    font-family: parag;
}

.post-title{
    font-size: 0.9rem;
    font-weight: 600;
    font-family: parag;
}

.post-content{
    font-size: 0.75rem;
    font-family: parag;
    opacity: 0.8;
    line-height: 24px;
}

.post-author {

    display: flex;
    gap: 2%;

}





  @media screen and (min-width: 768px) and (max-width: 1024px) {

    
   
   
    
}

@media screen and (min-width: 480px) and (max-width: 768px) {

    .blog_slogan {
        width: 100%;
        font-size: 1.8rem;
    }
    
    .post-date{
        font-size: 0.8rem;
    }

    .post-content{
        font-size: 0.7rem;
    
        opacity: 0.8;
        line-height: 20px;
    }

   
    

}



@media screen and (max-width: 480px) {

    
    .blog_slogan {
        width: 100%;
        font-size: 1.7rem; 
    }

    .post-date{
        font-size: 0.7rem;
    }
    
    .post-content{
        font-size: 0.65rem;
    
        opacity: 0.8;
        line-height: 20px;
    }
    
}