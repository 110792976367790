.Blog{
   
    padding-top: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 150px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.blog_slogan {

    width: 50%;
}

.blog-catgs-bar {

    margin-top: 41px !important;
    display: flex;
    font-size: smaller;
    gap: 0.5vw;
}

.blog-catg{
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    font-weight: 700;
    color: white;

}



.blog-catg:hover{
   
    color: rgb(119, 2, 2) !important;
}

.blog-catg:focus{
   
    color: rgb(119, 2, 2) !important;
}

.blog-catg:active{
   
    color: rgb(119, 2, 2) !important;
}


.blog_posts{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr);
    row-gap: 60px;
    column-gap: 40px;
    
}

.post{
     width: 100%;
}

.post-img:hover{
    opacity: 1;
    cursor: pointer;

}

.post p{
font-family: parag !important;
}

.post-img{

    width: 100%;
    border-radius: 25px;
    width: auto-fill;
    transition: 200ms;
    opacity: 0.8;
}

.post-type{
    font-size: 0.75rem;
    font-family: parag !important;
    opacity: 0.4;

}

.post-title{
    font-size: 0.9rem;
    font-weight: 600;
    font-family: parag !important;

}

.post-descriptiom{
    font-size: 0.75rem;
    font-family: parag !important;
    opacity: 0.8;
    line-height: 20px;

}

.post-author {

    display: none;
    width: 100%;
    justify-content: center;
    gap: 2%;
    margin-top: 15px;
    font-family: parag !important;


}

.post-author-pic {

    width: 5ch !important;
    height: 5ch !important;
    border-radius: 200px;
}

.post-author-info {

    margin-left: 1%;
    margin-top: -0.85vw !important;


}

.post-author-name {

    font-size: 0.8rem;
    font-family: parag !important;


}

.post-author-date {

    font-size: 0.7rem;
    font-family: parag !important;
    color: rgb(209, 209, 209) !important;
    margin-top: -10px;

}



  @media screen and (min-width: 768px) and (max-width: 1024px) {

    
    .Blog{

    }

.blog_slogan {
    
        width: 80%;
    }
    

    .blog_posts{
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);

    }
 
    .post-type{
        font-size: 0.65rem !important;
    }
    .post-title{
        font-size: 0.8rem !important;
    }
    .post-descriptiom{

        font-size: 0.6rem !important;
    }
   
    .post-author-pic {
 
        width: 3.5ch !important;
        height: 3.5ch !important;
        border-radius: 200px;
    }
    
    
    .post-author-name {
    
        font-size: 0.65rem !important;
    
    }
    
    .post-author-date {
    
        font-size: 0.55rem !important;
        color: rgb(209, 209, 209) !important;
        margin-top: -10px;
    
    }


   
    
}

@media screen and (min-width: 480px) and (max-width: 768px) {

    .Blog{

    }

    .blog_slogan {
        width: 100%;
        font-size: 1.8rem;
    }

    
    .blog-catgs-bar{

        margin-top: 20px;
    }
    .blog-catg{

        font-size: 0.65rem;
    }


    .blog_posts{
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);

    }
 
   
    
    .post-type{
        font-size: 0.65rem !important;
    }
    .post-title{
        font-size: 0.8rem !important;
    }
    .post-descriptiom{

        font-size: 0.6rem !important;
    }
   
    .post-author-pic {
 
        width: 4ch !important;
        height: 4ch !important;
        border-radius: 200px;
    }
    
    
    .post-author-name {
    
        font-size: 0.65rem !important;
    
    }
    
    .post-author-date {
    
        font-size: 0.55rem !important;
        color: rgb(209, 209, 209) !important;
        margin-top: -10px;
    }

    .post-author-info {

        margin-left: 1%;
        margin-top: -1vw !important;
    
    
    }


}


@media screen and (max-width: 520px) {

    .Blog{

    }

    .blog-catgs-bar{

        margin-top: 30px;
        gap: 30px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-items: left;
       margin-left: 4px;
 
    }
    .blog-catg{

       font-size: 0.7rem;
      
    }
    
    .catgs-bar-dash{
        display: none;
    }
    
}


@media screen and (max-width: 480px) {

    .Blog{

    }
       
    
    .blog_slogan {
        width: 100%;
        font-size: 1.7rem;
    }


    .blog_posts{
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(1,1fr);

    }

 
    .post-type{
        font-size: 0.6rem !important;
    }


    .post-author-info {

        margin-left: 1%;
        margin-top: -2.5vw !important;
    
    
    }
}