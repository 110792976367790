.Team {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    padding-bottom: 100px;
    height: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title {
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    gap: 2.5%;
}


.horizontal-line {
    width: 7%;
    height: 2.5px;
    background-size: 1px 2px, 1px 2px, 10px 10px;
    background-position: 0px 1px, 0px 1px, 9px 5px;
    background-repeat: no-repeat;
    margin: 20px 0;
}

.team-slogan {

    width: 50%;
}

.team-content {

    font-size: 0.7rem;
    line-height: 24px;
    width: 50%;
    margin-top: -1vh;
}


.team-learn-more {
    color: white;
    background: linear-gradient(to right, blueviolet, purple);
    margin-left: 87%;
    margin-top: -4vh;
    width: 13%;
    border-radius: 30px;
    border: 0;
    padding: 1vw;
}

.team-learn-more-480px {
    width: 110px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.55rem;

    color: white;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    border-radius: 30px;
    border: 0;
    display: none;
}


.team-members {

   display:grid;
   grid-template-columns: repeat(2,1fr);
   grid-template-rows: repeat(2,1fr);    

   width: 100%;
   max-width: 1200px;

   gap: 2%;

   margin-top: 70px;
}

.team-member{


    position: relative;

}

.team-member-bckgImg{

    border-radius: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    transition: 0.4s;
}

.overlay{

    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.4s ease;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    border-radius: 0 0 20px 20px;
    height: 25%;
    width: 100%;
    transform: translateY(15%);
}

.team-member-content{

   padding: 1% 5% 0 5%;

}

.team-member:hover .overlay{
    opacity: 1;
    transform: translateY(0);
}

.team-member:hover .team-member-bckgImg{
   filter: brightness(110%);
}

.team-member-title{

    font-size: 0.85rem;
    font-family: 'Roboto';
}

.team-member-desc{

    font-size: 0.7rem;
    font-family: 'Roboto';
    line-height: 17px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {


  
    .team-content {
        width: 70%;
        margin-top: 8px;
        }    




    .team-learn-more {
        width: 170px;
        padding-top: 13px;
        padding-bottom: 13px;
        margin-left: auto;
    }

    .overlay{

        
        height: 13ch;
       
    }
}


@media screen and (min-width: 480px) and (max-width: 768px) {


    .Team {
        /* align-items: center; */
        height: calc(fit-content + 10ch)
    }


    .team-slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.9rem;
    }

    .team-content {
        /* text-align: center; */
        font-size: 0.65rem;
        line-height: 15px;
        width: 65%;


    }


    .team-learn-more {
        width: 130px;
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 0.65rem;
        margin-top: -20px;
        margin-left: auto;
    }

    .overlay{

        
        height: fit-content;
        

       
    }

    .team-members{
        margin-top: 25px;
    }

    .team-member-title{
        font-size: 0.7rem;
        color: white;
    }
    .team-member-desc{
        font-size: 0.6rem;
        line-height: 14px;
        color:  rgb(209, 209, 209) ;
    }
}


@media screen and (max-width: 480px) {

    .Team {
        /* align-items: center; */
        height:auto;
        gap:8px;
    }


    .team-slogan {

        width: 100%;
        font-size: 1.9rem;;
    }
    
    .team-content {
    
        font-size: 0.6rem;
        line-height: 15px;
        width: 100%;
    }
    
    .team-learn-more {
       
        display: none;
    }

    .team-learn-more-480px {
        width: 120px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 0.6rem;
        margin-top: 5px;
       display: block;
    }


    .team-members{
        grid-template-columns: auto;
        grid-template-rows: auto;
        gap: 20px;
        margin-top: 25px;
    }

    .overlay{
        height: auto;
    }
    
    .team-member-title{
        font-size: 0.7rem;
        color: rgb(255, 255, 255);
    }
    .team-member-desc{
        font-size: 0.6rem;
        line-height: 14px;
        color:  rgb(255, 255, 255) ;
    }
   

}