.Animated_Blob {
    height: 100vmin;
    position: fixed;
    top: 7.5%;  
    z-index: -1;
    filter: drop-shadow(50px 50px 50px rgba(233, 100, 67, 0.7)); /* Adjust the values as needed */
    transform: translate3d(0, 0, 0);

    
  }
  
  
  path {
    cursor: pointer;
  }


  
.Animated_Blob:nth-child(1){
    filter: blur(5px);

  }
  
  .Animated_Blob:nth-child(2){
    filter: blur(30px);
   
  }
  