.radial_5 {
  background-image: url("../../components/Images/1.svg");
  background-repeat: no-repeat;

}

.about{
  background-image: url('../../components/Images/about_page.svg');
  background-repeat: no-repeat;

}

.tex_to_image{
  background-image: url('../../components/Images/Ai_text_image_new.svg');
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment: fixed;

}

@keyframes radial_5 {

  0% {
    background-position: 125% center, 0% 50%;
  }

  50% {
    background-position: 125% 75%, 100% 50%;
  }

  100% {
    background-position: 125% 25%, 0% 50%;
  }

}




.linear_3 {
  background: linear-gradient(to left, #000, rgb(59, 4, 4), rgb(155, 29, 7));
  background-size: 150%;
  /* animation: linear_3 30s infinite ease-in-out; */

}

@keyframes linear_3 {

  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }

}



.radial_3 {
  background: radial-gradient(#000, rgb(77, 7, 65), rgb(5, 2, 54));
  background-size: 150%;
  /* animation: radial_3 40s infinite ease-in-out; */

}




@keyframes radial_3 {

  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }

}



.radial_4 {
  background: radial-gradient(#000, rgb(77, 7, 65), rgb(163, 55, 22), rgb(5, 2, 54));
  background-size: 100%;
  /* animation: radial_4 40s infinite ease-in-out; */

}




@keyframes radial_4 {

  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }

}




.linear_4 {
  background: linear-gradient(315deg, #000, rgb(77, 7, 65), rgb(163, 55, 22), rgb(5, 2, 54));
  background-size: 100% 160%;
  /* animation: linear_4 20s infinite ease-in-out; */

}



@keyframes linear_4 {

  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }

}





.portfolio_gradient {
  background: linear-gradient(315deg, rgb(58, 5, 75), rgb(21, 1, 58), rgb(68, 2, 2), rgb(0, 0, 0));
  background-size: 100% 160%;
  /* animation: linear_4 20s infinite ease-in-out; */


}


@keyframes portfolio_gradient {

  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }

}




.team_gradient {
  background: linear-gradient(315deg, rgb(58, 5, 75), rgb(21, 1, 58), rgb(68, 2, 2), rgb(0, 0, 0));
  background-size: 100% 160%;
  /* animation: linear_4 20s infinite ease-in-out; */

}


@keyframes team_gradient {

  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }

}

.footer_gradient {
  background: linear-gradient(315deg, #968b8b, rgb(0, 0, 0), rgb(77, 7, 65), rgb(0, 0, 0), rgb(70, 1, 1));
  background-size: 100% 160%;
  /* animation: footer_gradient 20s infinite ease-in-out; */


}


@keyframes footer_gradient {

  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }

}




.services_gradient {



  background:  linear-gradient(315deg, rgb(5, 2, 54), rgb(163, 55, 22), #000, rgb(77, 7, 65));
  background-size: 100% 160%;
  /* animation: services_gradient 20s infinite ease-in-out; */


}


@keyframes services_gradient {

  0% {
    background-position:   50% 0%;
  }

  50% {
    background-position:   50% 100%;
  }

  100% {
    background-position:    50% 0%;
  }

}



.test_gradient {
  background: linear-gradient(315deg, rgb(5, 2, 54), rgb(163, 55, 22), #000, rgb(77, 7, 65));
  background-size: 100% 160%;
  /* animation: linear_4 20s infinite ease-in-out; */
}


@keyframes test_gradient {

  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }

}







.ai_chat_gradient {



  background: url(../../components/Images/ai_chat/el4.png) no-repeat , url(../../components/Images/ai_chat/el3.png) no-repeat,  url() no-repeat , linear-gradient(315deg, rgb(5, 2, 54), rgb(163, 55, 22), #000, rgb(77, 7, 65));
  background-size: 10%,10%,50%,100% 160%;
  animation: ai_chat_gradient 80s  ;
  animation-timing-function: linear;  
  animation-iteration-count: infinite;

}




@keyframes ai_chat_gradient {

  0% {
    background-position:left 30% , right 30% , center 5% ,50% 0%;
    

  }
  25%{
    background-position: 50% 60% , 50% 80% ,center 5%, 50% 50%;
  }

  50% {
    background-position:right 30% , left 30% ,center 5% , 50% 100%;
  }

  75% {
    background-position: 50% 40% , 50% 20% ,center 5% ,50% 50%;
  }

  100% {
    background-position: left 30% , right 30% ,center 5% , 50% 0%;

  }

}




