

.Consultation{

    display: flex;
    flex-direction: column;
    /* background-color: #191c26; */
    align-items: center;
    justify-content: center;
    padding: 5vw;
    color: white;
    font-family: 'Orbitron';
    padding-bottom: 100px;
    text-align: center;

}



.consultation-content{

    margin-top: 10px;
    width: 60%;
    font-size: 0.7rem;
    color: rgb(209, 209, 209) !important;
    line-height: 24px;
    text-align: center; 
    font-family: 'Roboto';

}

.consultation-button{

    margin-top: 15px;
    font-size: smaller;
    padding: 15px;
    width:27ch;
    text-align: center;
    border-radius: 30px;
    border: 1px solid rgb(255, 255, 255);
    color: rgb(219, 219, 219);
    font-family: 'Roboto';

    background: linear-gradient(to right, rgba(38, 6, 68, 0.522), rgba(0, 0, 0, 0.501));
    background-size: 0% ;
    background-position: 1% ;
    background-repeat: no-repeat ;    border-radius: 30px;
    transition: all 0.5s ease-out ;
    font-size: 0.9rem  !important;
    font-weight: bold;
}

.consultation-button:hover{
    background-size: 100%;
    font-weight: bold;
    /* border: 0; */
    border: 0.25px solid rgb(190, 190, 190,0.7) ; 
}

@media screen and (min-width: 768px) and (max-width: 1024px) {


    .Consultation{

       margin-top: 20px;
       
    }

    .consultation-content{
    
     width: 80%;
    }
  

    .consultation-title{

       font-size: 1.5rem;
         
    }

    .consultation-content{

        font-size: 0.6rem;
        line-height: 18px;
          
     }

     .consultation-button{

        margin-top: 10px;
        font-size: 0.7rem;
        padding: 15px;
        width:27ch;
        text-align: center;
        background-color: transparent;
        border-radius: 30px;
        border: 1px solid rgb(255, 255, 255);
        color: rgb(219, 219, 219);
    }
}


@media screen and (min-width: 480px) and (max-width: 768px) {


    .Consultation{

        margin-top: 20px;
        margin-bottom: -30px;
     }
 
     .consultation-content{
     
      width: 80%;
     }
   
 
     .consultation-title{
 
        font-size: 1.4rem;
          
     }
 
     .consultation-content{
 
        font-size: 0.5rem;
        line-height: 12px;
        width: 80%;

        margin-top: 5px;
           
      }
 
      .consultation-button{
 
         margin-top: 10px;
         font-size: 0.6rem;
         padding: 13px;
         width:24ch;
         text-align: center;
         background-color: transparent;
         border-radius: 30px;
         border: 1px solid rgb(255, 255, 255);
         color: rgb(219, 219, 219);
     }

    
}

@media screen and (max-width: 480px) {

    
    .Consultation{


        padding-top: 30px;
        padding-bottom: 40px;
    }

    .consultation-title{
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
        padding-bottom: 10px;
        
    }
    .consultation-content{
        width: 100%;
        font-size: 0.45rem;

        line-height: 13px;
        margin-top: 50px;
    }

    .consultation-button{

        font-size: 0.55rem;
        width: 210px;
        padding-top:15px ;
        padding-bottom:15px ;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 10px;
        /* margin-bottom: 20px; */
    }
   

}