.Job {

    display: flex;
    flex-direction: column;
    padding: 5vw;
    position: relative;
    height: calc(fit-content + 10ch);
    padding-bottom: 200px;
    color: white;
}

.job{
    position: relative;
}

.job_slogan {

    width: 50%;

}



.job_title{
    font-size: 0.95rem;
    font-weight: 700;
    font-family: parag;
  
}

.job_description{

    margin-top: 20px !important;
    font-size: 0.719rem;
    font-family: parag;
    width: 50%;
    line-height: 21px;

}

.job_details{
    display: flex;
    margin-top: 20px !important;
    gap: 1%;

}

.job_location{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem !important;
    font-family: parag;
    opacity: 0.6;
    border: 1px solid rgba(255, 255, 255, 0.555);
    border-radius: 20px;
    padding-right: 15px;
    padding-left: 15px;
    height: 30px;
    gap: 5px;
}

.job_type{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem !important;
    font-family: parag;
    opacity: 0.6;
    border: 1px solid rgba(255, 255, 255, 0.555);
    border-radius: 20px;
    padding-right: 15px;
    padding-left: 15px;
    height: 30px;
    gap: 5px;
}

.job_apply_button {
    font-size: smaller;
    font-family: parag;
    color: white;
    background-color: rgb(76, 11, 136);
    width: 100px;
    border-radius: 30px;
    border: 0;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    
}

.job svg{

    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    width: 15px;
    height: 15px;
    color: rgba(255, 255, 255, 0.5);
}


.job_definition{

    margin-top: 30px;
}

.job_definition_title{


    font-size: 0.8rem;
    font-weight: 700;
    font-family: parag;

 
}

.definition_points{

    display: flex;
    flex-direction: column;
    font-size: 0.719rem;
    font-family: parag;

}


.dark{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.445);
    z-index: 999;
    top: 0;
    left: 0;
  
}


.jobForm{

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px; 
    padding: 40px;
    border-radius: 20px;
    width: 30%;
    height: 60vh;
    background-color: white;
    color: black;
    position: fixed; 
    overflow-y: auto; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.confirmation-message{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    gap: 20px;
    border-radius: 20px;
    width: fit-content;
    height: auto;
    background-color: white;
    color: black;
    position: fixed; 
    overflow-y: auto; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}


.confirm-message{
    margin-top: 30px;
    font-size: 0.8rem;
    font-weight: 700;
    width: 100%;
}

.continue-button{

    font-size: smaller;
    color: white;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    width: 150px;
    border-radius: 30px;
    border: 0;
    padding: 10px;
}
  

.input-title{
    font-size: 0.8rem;
    font-weight: 700;
}

.jobForm-input{
    width: 80%;
    padding:  0 7.5% 0  7.5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.781);
    outline: 0;
    border-color: rgba(0, 0, 0, 0.13);
    height: 8ch;
    font-size: 0.7rem;
}

.jobForm-apply-button{

    font-size: smaller;
    color: white;
    background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
    width: 100px;
    border-radius: 30px;
    border: 0;
    padding: 10px;
    margin-left: auto;
}



  @media screen and (min-width: 768px) and (max-width: 1024px) {


    .job_slogan {
        width: 50%;
    }  

    
}  

@media screen and (min-width: 480px) and (max-width: 768px) {


    .job_slogan {
        width: 100%;
        /* text-align: center; */
        font-size: 1.8rem;
    }

    .jobForm{
        width: 50%;
        height: auto;
    }


}

@media screen and (max-width: 480px) {


    .job_slogan {

        width: 100%;
        font-size: 1.7rem;

    }
    
    
    .job_apply_button {
     
        position: relative;
       margin-top: 25px;
       padding: 7px;
    }

    .jobForm{
        width: 70%;
        height: auto;
    }

    .job_description{
        width: 100%;
    }

}