.Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 200px;
    padding-top: 100px;

    height: auto;
    color: white;
}

.contact-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-slogan {

    width: 100%;
    font-family: 'Orbitron';
}

.contact-content {

    font-size: 0.7rem;
    line-height: 24px;
    width: 70%;
    font-family: 'Roboto';

}
.contact_links{
    margin-top: 30px;
    
    display: flex;
    gap: 25px;

}
.contact-link{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* gap: 20px; */
}

.contact-link svg{

    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    width: 25px;
    height: 25px;
    padding: 10px;
    color: rgba(255, 255, 255, 0.5);
}

.contact-link h6{
      
    font-size: 0.8rem;
    font-weight: 750;
    font-family: 'Roboto';
    color: rgb(255, 255, 255);
    text-align: center;
    margin-top: 15px;


}

.link-info{
    height: 100%;
    padding-bottom: 8px;
}

.link-info p{
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.596);
    margin-top: -20px;
    font-family: 'Roboto';
    text-align: center;


   
}



.contact-us-form {

    display: flex;
    flex-direction: column;
    background-color: #ffffff27;
    align-items: center;
    justify-content: end;
    color: black;
    min-width: 320px ;
    width: 70vmin ;
    max-width: 650px;
    padding: 30px;
    border-radius: 12px;
    gap: 20px;
    height: 52.5ch;    
    margin-left: 0 !important;
    margin-top: 30px;
  }
  

  
  .contact-us-form-input {
    
    width: 80%;
    padding:  0 7.5% 0  7.5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.781);

    outline: 0;
    border-color: rgba(0, 0, 0, 0.13);
    height: 8ch;
    font-size: 0.7rem;
    font-family: 'Roboto';

  
  }
  
  
  .contact-us-form-message {
    
    width: 80%;
    padding: 5% 7.5% 0 7.5%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.733);
    /* background-color: rgba(255, 255, 255); */

    outline: 0;
    border-color: rgba(0, 0, 0, 0.13);
    height: 21ch;
    font-size: 0.7rem;
    font-family: 'Roboto';

  
  }
  
  .contact-us-form-send-button{
  
      color: white;
      background: linear-gradient(to right, rgba(137, 43, 226, 0.822), rgba(128, 0, 128, 0.801));
      width: 80%;
      border-radius: 30px;
      border: 0;
      padding: 15px;
      font-size: 0.7rem;
      font-family: 'Roboto';

  
  }






  @media screen and (min-width: 768px) and (max-width: 1024px) {

    
    
    .contact-slogan {
    
        width: 100%;
    }
    
    .contact-content {
    
        line-height: 24px;
        width: 70%;
    }
    /* .contact-us-form{
        margin-top: 30px;
        width: 80%;
        height: 57ch;
    } */
    
}

@media screen and (min-width: 480px) and (max-width: 768px) {

    .contact_links{
    
     
    }

    .Contact{
        
        flex-direction: column;
    }

    .contact-slogan {
        width: 100%;
        font-size: 1.8rem;
    }

    .contact-content {
        width: 80%;
        font-size: 0.65rem;
        margin-top: 0px;
        line-height: 22px;

    }

    /* .contact-us-form{
        height: 55ch;
        width: 60%;
        margin-top: 40px;
        margin-left: 15%;

    } */

}





@media screen and (max-width: 480px) {


    .contact_links{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        justify-items: space-between;
        align-content: space-between;
        width: 90%;
        }

    .Contact{
        
        flex-direction: column;
      
    }
   
    
    .contact-slogan {
        width: 100%;
        font-size: 1.7rem;
    }
    .contact-content {
    
        width: 100%;
        font-size: 0.60rem;
        line-height: 21px;
    }

    .contact-us-form{
        width: 50vw !important;
        min-width: none !important;
        max-width: none !important;
        padding: 20px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        margin-left: 2.5%;
        margin-top: 40px;
    }
   
 
}