

.Main_3{

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0vh;
    height: auto;
    gap: 50px;
    padding: 100px;
    /* background-color: #111111; */
    color: white;
    padding-bottom: 100px;

}



.card{
    
    
    border: 1px solid white;
    padding: 100px 0px 180px 0px !important;
    border-radius: 25px;
    width: 100% !important;
    min-width: 300px !important;
    max-width: 400px !important;
    max-height: 800px !important;
    height: 100px !important;
    transition: width, background-color 0.99s linear;
    text-align: center;
    cursor: pointer;
    word-wrap:break-word !important;

  }

.card-title{
  font-family: 'Orbitron';
 
  
}

/* .head_shadow{
  text-shadow: 3px 3px 2px rgb(8, 58, 39),
  0px 1px 15px rgb(0, 0, 0) ;
}

.parag_shadow{
  text-shadow: 3px 3px 2px rgb(29, 29, 29),
  0px 1px 15px rgb(0, 0, 0) ;
} */

.card-icon{

    width: 50px;
}

.content{
    
    font-size: 0.80rem !important;
    color: rgb(209, 209, 209) !important;
    line-height: 20px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: -50px;
    font-family: 'Roboto';
    font-weight: 600 !important;
    text-shadow: 3px 3px 2px rgb(29, 29, 29),
    0px 1px 15px rgb(0, 0, 0) ;
   
}

@media screen and (min-width: 768px) and (max-width: 1024px) {


  .Main_3{
    
    display: grid;

    grid-template-rows: repeat(2,1fr );
    grid-template-columns: repeat(2,1fr );
    column-gap: 4%;
    row-gap: 3%;
  
  }
   
   

   .third{
    
    

   }
}


@media screen and (min-width: 600px) and (max-width: 768px) {


  .Main_3{
    
    display: grid;
    grid-template-rows: repeat(2,1fr );
    grid-template-columns: repeat(2,1fr );
    row-gap: 3%;
    column-gap: 4%;
    justify-items: center;
  
  }
  
  
     
  }



  @media screen and (max-width: 600px) {


    .Main_3{
      
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
      gap: 2%;
      justify-items: center;
      padding-bottom: 100px;
    
    }
     
    
       
    }  




    @media screen and (max-width: 480px) {


      .Main_3{
        
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        gap: 2%;
        justify-items: center;
        padding-bottom: 100px;
      
      }

      .card{
        max-width: 80vmax !important;
      }
    }