body{
    background: linear-gradient(90deg, rgb(0, 0, 0)  , rgb(65, 34, 80) 30%,   rgb(0, 0, 0) 100%);
    /* background: radial-gradient(circle, rgba(12,8,71,1) 0%, rgba(31,83,78,1) 0%, rgba(16,16,19,1) 100%);
    background-attachment: fixed; */
}



.slide_in{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle ,  rgb(100, 40, 124) 30%,   rgb(0, 0, 0) 100%);
    transform-origin: bottom;
    z-index: 99;

}


.slide_out{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle ,  rgb(100, 40, 124) 30%,   rgb(0, 0, 0) 100%);
    transform-origin: bottom;
    z-index: 99;

}